import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-card-element',
  templateUrl: './card-element.component.html',
  styleUrls: ['./card-element.component.scss'],
})
export class CardElementComponent {
  @Input() title = '';
  @Input() link = '';

}
