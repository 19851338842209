<ion-content [scrollEvents]="true" class="no-scroll" id="content_div" force-overscroll="false">
    <app-header-banner></app-header-banner>
    <ion-grid [class.desktop-content-padding]="environment.desktop">
        <ion-row>
            <ion-col size="12" size-lg="8" offset-lg="2">
                <ion-row class="ion-margin-vertical">
                    <ion-col class="ion-text-center">
                        <app-user-salutation></app-user-salutation>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="clientService.client?.additionalInformation || clientService.client?.hasNextHolidayText"
                         class="ion-margin-top">
                    <ion-col class="ion-text-center">
                        <div class="app-icon-button">
                            <div *ngIf="clientService.client?.hasNextHolidayText"
                                 [class.ion-margin-bottom]="clientService.client?.additionalInformation">{{ clientService.client?.nextHoliday.infoInclDate }}</div>
                            <div *ngIf="clientService.client?.additionalInformation">{{clientService.client.additionalInformation}}</div>
                            <ion-icon name="information-circle-outline"></ion-icon>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="featuredArticles.length" class="ion-justify-content-center">
                    <ng-container *ngFor="let article of featuredArticles">
                        <ion-col size="6" size-lg="4" size-xl="3">
                            <app-article-card-item (click)="openFeaturedArticle(article)"
                                                   [article]="article"></app-article-card-item>
                        </ion-col>
                    </ng-container>
                </ion-row>
                <ion-row *ngIf="!clientService.client?.features?.lunchMenu" style="margin: 30px 0;">
                    <ion-col>
                        <app-icon-button iconName="restaurant-outline" routerLink="/products"
                                         title="Produkte"></app-icon-button>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="clientService.client?.features?.lunchMenu" style="margin: 30px 0;">
                    <ion-col>
                        <app-icon-button iconPath="assets/images/menu.svg" routerLink="/menus"
                                         title="Menüs"></app-icon-button>
                    </ion-col>
                    <ion-col>
                        <app-icon-button iconName="restaurant-outline" routerLink="/products"
                                         title="Produkte"></app-icon-button>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="notificationService.pushNotifications.length">
                    <ion-col>
                        <app-card-element title="Benachrichtigungen" link="/notifications">
                            <ion-list style="background: transparent">
                                <ng-container
                                        *ngFor="let pushNotification of notificationService.pushNotifications | slice:0:5">
                                    <ion-item style="--padding-start: 0; --inner-padding-end: 0;"
                                              (click)="notificationService.handlePushNotification(pushNotification)">
                                        <ion-thumbnail slot="start"
                                                       style="padding-top: 5px; padding-bottom: 5px; padding-right: 10px;">
                                            <div *ngIf="pushNotification.image || this.clientService.client?.articlePlaceholder"
                                                 [style.background-image]="pushNotification.image ? 'url(' + pushNotification.image.path + ')' : 'url(' + clientService.client?.articlePlaceholder.path + ')'"
                                                 style="height: 100%; background-size: contain; border-radius: 5px; background-repeat: no-repeat; background-position: center">
                                            </div>
                                        </ion-thumbnail>
                                        <ion-label>
                                            <h3 style="font-weight: 700;font-size: 11px;line-height: 1.2;">{{pushNotification.title}}</h3>
                                            <div style="font-size: 11px;">{{pushNotification.description}}</div>
                                            <div style="font-size: 8px;">{{pushNotification.sent | date: 'dd.MM.yyyy - HH:mm'}}</div>
                                        </ion-label>
                                        <ion-icon slot="end" name="ellipse" color="primary" style="font-size: 12px"
                                                  *ngIf="!pushNotification.seen"></ion-icon>
                                    </ion-item>
                                </ng-container>
                            </ion-list>
                        </app-card-element>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
    </ion-grid>
    <app-footer></app-footer>
</ion-content>
