import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '../../services/notification/notification.service';
import {Subscription} from 'rxjs';
import {ClientService} from '../../services/client.service';
import {environment} from 'src/environments/environment';
import {ArticleService} from '../../services/article.service';
import {Article} from '../../models/article.model';
import {ViewWillEnter} from '@ionic/angular';
import {CategoryService} from '../../services/category.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.page.html',
    styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit, OnDestroy, ViewWillEnter {
    private subscriptions: Subscription[] = [];

    public environment = environment;

    public featuredArticles: Article[] = [];

    constructor(
        public clientService: ClientService,
        public articleService: ArticleService,
        private categoryService: CategoryService,
        public notificationService: NotificationService,
        private router: Router,
        private cdRef: ChangeDetectorRef
    ) {
    }

    async ngOnInit() {
        if (this.notificationService.pushNotificationToken) {
            await this.notificationService.getNotifications(this.notificationService.pushNotificationToken);
        }
        this.subscriptions.push(this.notificationService.onTokenReceived.subscribe(async token => {
            await this.notificationService.getNotifications(token);
            this.cdRef.detectChanges();
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    async ionViewWillEnter(): Promise<void> {
        try {
            this.featuredArticles = await this.articleService.getFeaturedArticles();
        } catch (e) {
            this.featuredArticles = [];
        }
    }

    public openFeaturedArticle(article: Article) {
        this.categoryService.selectedCategory = article.categories[0];
        this.router.navigateByUrl('/products/' + article.id);
    }
}
