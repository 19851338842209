import {Serializable} from 'src/app/models/serializable.abstract';
import {MenuArticle} from 'src/app/models/menu-article.model';
import {MenuCategory} from 'src/app/models/menu-category.model';

export class AdditionalArticleDTO extends Serializable {
    title: string;
    additionalPrice: number;
    menuArticle: MenuArticle;
    menuCategory: MenuCategory;

    deserialize(input: any): Serializable {
        super.deserialize(input);

        this.deserializeComplexType('menuArticle', input, MenuArticle);
        this.deserializeComplexType('menuCategory', input, MenuCategory);

        return this;
    }

    serialize(): any {
        return {
            article: this.menuArticle.id,
            category: this.menuCategory.id
        };
    }
}

export class AdditionalArticle extends AdditionalArticleDTO {

}
