<ion-header *ngIf="!environment.desktop">
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-back-button [defaultHref]="'/products'"
                             text="Zurück"></ion-back-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content id="content">
    <ng-container *ngIf="environment.desktop">
        <app-header-banner></app-header-banner>
        <ion-grid style="margin: 60px 0">
            <ion-row>
                <ion-col size="12" size-lg="8" offset-lg="2">
                    <ion-row>
                        <ion-col size="12" size-lg="6">
                            <div class="article-image-wrapper desktop"
                                 *ngIf="article?.coverImage || this.clientService.client?.articlePlaceholder"
                                 [style.background-image]="article?.coverImage ? 'url(' + article?.coverImage.path + ')' : 'url(' + clientService.client?.articlePlaceholder.path + ')'"></div>
                            <ion-buttons class="ion-no-margin" style="margin-top: 10px">
                                <ion-back-button
                                        [defaultHref]="'/products'"
                                        text="Zurück zu allen Artikeln"></ion-back-button>
                            </ion-buttons>
                        </ion-col>
                        <ion-col class="articleTitle" size="12" size-lg="5" offset-lg="1">
                            <h1 class="product-title">
                                <ng-container *ngIf="!article">
                                    <ion-skeleton-text animated style="width: 50%; height: 28px;"></ion-skeleton-text>
                                </ng-container>
                                {{article?.title}}</h1>
                            <div class="description" [innerHTML]="article?.description"></div>
                            <div class="price-box">
                                <div class="price" style="font-size: 24px">
                                    <em>
                                        <ng-container
                                                *ngIf="!(article?.specialPrice && article?.specialPriceValidFrom && article?.specialPriceValidUntil)">
                                            <b>{{article?.price| currency: 'EUR' }}</b>
                                        </ng-container>
                                        <ng-container
                                                *ngIf="article?.specialPrice && article?.specialPriceValidFrom && article?.specialPriceValidUntil">
                                            statt <s>{{ article?.price | currency: 'EUR' }}</s>
                                            <br>
                                            <b>{{ article?.specialPrice | currency: 'EUR' }}</b>
                                        </ng-container>
                                        pro {{ article?.unit.label }}
                                    </em>
                                </div>
                                <div *ngIf="article?.specialPrice && article?.specialPriceValidFrom && article?.specialPriceValidUntil"
                                     class="price">
                                    <em>
                                        Aktion gültig vom {{ article?.specialPriceValidFrom | date: 'dd.MM.yyyy' }} bis
                                        zum {{ article?.specialPriceValidUntil | date: 'dd.MM.yyyy' }}
                                    </em>
                                </div>
                                <div *ngIf="article?.hoursLeadTime > 0" class="price">Vorbereitungszeit:
                                    <em>{{article?.hoursLeadTime}}
                                        h</em>
                                </div>
                                <div *ngIf="+article?.hoursLeadTime === 0" class="price">Abholung:
                                    <em>sofort</em>
                                </div>
                                <div *ngIf="article?.availableDays.length != 0" class="price">
                                    Verfügbar: {{availableDays}}
                                </div>
                            </div>
                            <ion-row>
                                <ion-col class="ion-no-padding">
                                    <ion-button (click)="decreaseAmount()" [disabled]="!isDecreasable"
                                                class="ion-no-margin" expand="full">
                                        <ion-icon name="remove-outline"></ion-icon>
                                    </ion-button>
                                </ion-col>
                                <ion-col
                                        class="ion-text-center ion-align-items-center ion-justify-content-center ion-no-padding ion-align-self-center">
                                    <span>{{amount}} {{ article?.sellUnit.label }}</span>
                                </ion-col>
                                <ion-col class="ion-no-padding">
                                    <ion-button expand="full" class="ion-no-margin"
                                                [disabled]="!isIncreasable"
                                                (click)="increaseAmount()">
                                        <ion-icon name="add-circle-outline"></ion-icon>
                                    </ion-button>
                                </ion-col>
                            </ion-row>
                            <ion-button (click)="addNote()" class="ion-no-margin" expand="full"
                                        fill="clear"
                                        style="margin-top: 10px; border: 1px solid var(--ion-color-primary);">
                                <ion-icon name="create-outline"></ion-icon>
                                Anmerkung
                            </ion-button>
                            <ion-button expand="full" class="ion-no-margin" style="margin-top: 10px"
                                        (click)="addToBasket()" [disabled]="!article">In den Warenkorb
                            </ion-button>
<!--                            <ion-button id="open-product-extras-modal" expand="block" class="ion-no-margin" style="margin-top: 10px" [disabled]="!article">In den Warenkorb-->
<!--                            </ion-button>-->
                        </ion-col>
                        <ion-col *ngIf="topArticles.length>0" style="margin-top: 30px;">
                            <h2 style="font-size: 30px;">Das könnte Sie auch interessieren:</h2>
                            <ion-row class="as-tile-pair">
                                <ng-container *ngFor="let topArticle of topArticles">
                                    <ion-col class="as-tile" size="6" size-xl="3">
                                        <app-article-card-item [article]="topArticle"
                                                               [routerLink]="'/products/' + topArticle.id"></app-article-card-item>
                                    </ion-col>
                                </ng-container>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-grid>
        <app-footer></app-footer>
    </ng-container>
    <ng-container *ngIf="!environment.desktop">
        <ion-grid>
            <ion-row>
                <ion-col>
                    <div *ngIf="article?.coverImage || this.clientService.client?.articlePlaceholder"
                         [style.background-image]="article?.coverImage ? 'url(' + article?.coverImage.path + ')' : 'url(' + clientService.client?.articlePlaceholder.path + ')'"
                         class="article-image-wrapper"></div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="articleTitle">
                    <h2>
                        <ng-container *ngIf="!article">
                            <ion-skeleton-text animated style="width: 50%; height: 28px;"></ion-skeleton-text>
                        </ng-container>
                        {{article?.title}}</h2>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <div class="description" [innerHTML]="article?.description"></div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <div class="price-box">
                        <div class="price" style="font-size: 20px">
                            <em>
                                <ng-container
                                        *ngIf="!(article?.specialPrice && article?.specialPriceValidFrom && article?.specialPriceValidUntil)">
                                    <b>{{article?.price| currency: 'EUR' }}</b>
                                </ng-container>
                                <ng-container
                                        *ngIf="article?.specialPrice && article?.specialPriceValidFrom && article?.specialPriceValidUntil">
                                    statt <s>{{ article?.price | currency: 'EUR' }}</s>
                                    <br>
                                    <b>{{ article?.specialPrice | currency: 'EUR' }}</b>
                                </ng-container>
                                pro {{ article?.unit.label }}
                            </em>
                        </div>
                        <div *ngIf="article?.specialPrice && article?.specialPriceValidFrom && article?.specialPriceValidUntil"
                             class="price">
                            <em>
                                Aktion gültig vom {{ article?.specialPriceValidFrom | date: 'dd.MM.yyyy' }} bis
                                zum {{ article?.specialPriceValidUntil | date: 'dd.MM.yyyy' }}
                            </em>
                        </div>
                        <div *ngIf="article?.hoursLeadTime > 0" class="price">Vorbereitungszeit:
                            <em>{{article?.hoursLeadTime}}
                                h</em>
                        </div>
                        <div *ngIf="+article?.hoursLeadTime === 0" class="price">Abholung:
                            <em>sofort</em>
                        </div>
                        <div *ngIf="article?.availableDays.length != 0" class="price">
                            Verfügbar: {{availableDays}}
                        </div>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col class="ion-text-center" size="4">
                    <ion-button (click)="decreaseAmount()" [disabled]="!isDecreasable" class="ion-no-margin"
                                expand="full">
                        <ion-icon name="remove-outline"></ion-icon>
                    </ion-button>
                </ion-col>
                <ion-col class="ion-text-center" size="4">
                    <span>{{amount}} {{ article?.sellUnit.label }}</span>
                </ion-col>
                <ion-col class="ion-text-center" size="4">
                    <ion-button expand="full" class="ion-no-margin"
                                [disabled]="!isIncreasable"
                                (click)="increaseAmount()">
                        <ion-icon name="add-circle-outline"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="article?.minOrderAmount > 1">
                <ion-col class="ion-text-center">
                    Mindestbestellmenge von {{article?.minOrderAmount}} {{ article?.sellUnit.label }}
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-button (click)="addNote()" class="ion-no-margin" color="primary" expand="full"
                                fill="clear"
                                style="border: 1px solid var(--ion-color-primary);">
                        <ion-icon name="create-outline"></ion-icon>
                        Anmerkung
                    </ion-button>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="comment">
                <ion-col>
                    <div style="font-size: small; font-style: italic; line-height: 1; margin-top: 20px;">{{comment}}</div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <div style="margin-top: 20px;" *ngIf="topArticles.length>0">
                        <ion-label> Das könnte Sie auch interessieren:</ion-label>
                        <div class="scrolling-wrapper">
                            <ng-container *ngFor="let topArticle of topArticles">
                                <div class="card">
                                    <ion-card class="basketCard" [routerLink]="'/products/' + topArticle.id">
                                        <div class="top-article-image-wrapper"
                                             *ngIf="topArticle?.coverImage || this.clientService.client?.articlePlaceholder"
                                             [style.background-image]="topArticle?.coverImage ? 'url(' + topArticle?.coverImage.path + ')' : 'url(' + clientService.client?.articlePlaceholder.path + ')'"></div>

                                        <div style="margin:5px;">
                                            <ion-label class="articleCardTitle"
                                                       style="letter-spacing: normal; font-size: 14px; font-weight: normal;">{{(topArticle?.title.length > 9) ? (topArticle?.title | slice:0:9) + '...' : (topArticle?.title)}}</ion-label>
                                        </div>
                                    </ion-card>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-button (click)="addToBasket()" [disabled]="!article" expand="full">In den Warenkorb</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>
