import {Serializable} from './serializable.abstract';
import {MenuArticle} from './menu-article.model';
import {BaseArticle} from './base-article.model';

export class LunchCategoryDTO extends Serializable {
    title: string;
    menuArticles: MenuArticle[] = [];
    selectionRequired = false;

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('menuArticles', input, MenuArticle);

        if (!this.selectionRequired) {
            const noSelectionArticle = new MenuArticle();
            noSelectionArticle.title = 'ohne';
            noSelectionArticle.id = 0;

            const article = new BaseArticle();
            article.title = 'ohne';
            article.id = 0;
            article.icon = 'ban';

            noSelectionArticle.article = article;

            this.menuArticles.push(noSelectionArticle);
        }

        return this;
    }
}

export class LunchCategory extends LunchCategoryDTO {
    get checkedLunchArticles(): MenuArticle[] {
        return this.menuArticles.filter(menuArticle => menuArticle.checked);
    }

    get checkedLunchArticlesWithoutFake(): MenuArticle[] {
        return this.menuArticles.filter(menuArticle => menuArticle.checked && menuArticle.id > 0);
    }

    public uncheckLunchArticles(): void {
        this.menuArticles.forEach(menuArticle => menuArticle.checked = false);
    }
}
