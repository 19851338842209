import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

declare var Stripe: any;

@Injectable({
    providedIn: 'root'
})
export class StripeService {

    public stripe: any;

    constructor() {
        if (environment.stripe && environment.stripe.pk) {
            this.stripe = Stripe(environment.stripe.pk);
        }
    }

    public createCardElement(id: string) {
        if (!this.stripe) {
            return;
        }
        const style = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        const elements = this.stripe.elements();

        const cardElement = elements.create('card', {style});

        cardElement.mount(id);

        return cardElement;
    }
}
