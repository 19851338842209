import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {ModalNoConnectionComponent} from '../modals/modal-no-connection/modal-no-connection.component';
import {ModalController} from '@ionic/angular';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private modalController: ModalController
    ) {
    }

    private static updateHeader(req: HttpRequest<any>): HttpRequest<any> {
        req = req.clone({
            params: req.params.set('client', environment.clientId.toString(10))
        });
        return req;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = RequestInterceptor.updateHeader(request);
        return next.handle(request)
            .pipe(
                map(
                    data => {
                        return data;
                    }
                ),
                catchError(
                    error => {
                        if (error.status === 0) {
                            this.modalController.create({
                                component: ModalNoConnectionComponent,
                            }).then(modal => {
                                modal.present();
                            });
                        }
                        return throwError(error);
                    }
                )
            );
    }
}
