import {Serializable} from './serializable.abstract';
import {Image} from './image.model';
import {Holiday} from './holiday';

export class ClientDTO extends Serializable {
    id: number;
    title: string;
    imprintUrl: string;
    privacyUrl: string;
    conditionsUrl: string;
    companyName: string;
    firstName: string;
    lastName: string;
    street: string;
    zip: string;
    location: string;
    email: string;
    phone: string;
    mobile: string;
    guestOrderPossible: string;
    stripePaymentPossible: boolean;
    description: string;
    openingHours: string;
    additionalInformation = '';
    additionalInformationLunchMenu = '';
    articlePlaceholder: Image;
    logo: Image;
    banner: Image;
    nextHoliday: Holiday;
    availability: {
        status: number,
        title: string,
        description: string,
        nonOpeningDays: Date[],
        nonDeliveryDays: Date[],
    };
    textElements: {
        salutations: {
            morning: string,
            midday: string,
            afternoon: string,
            evening: string
        },
        infos: {
            menuNotAvailable: string,
        }
    };
    features: {
        allergens: boolean,
        automaticRefreshBackendModule: boolean,
        lunchMenu: boolean,
        priceCalculation: boolean,
    } = {allergens: false, automaticRefreshBackendModule: false, lunchMenu: false, priceCalculation: false};

    public deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexType('logo', input, Image);
        this.deserializeComplexType('banner', input, Image);
        this.deserializeComplexType('articlePlaceholder', input, Image);
        return this;
    }
}

export class Client extends ClientDTO {

    get hasNextHolidayText(): boolean {
        return this.nextHoliday && this.nextHoliday.showInfo;
    }
}
