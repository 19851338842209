import {Serializable} from './serializable.abstract';
import {LunchCategory} from './lunch-category.model';
import {Article} from './article.model';
import {MenuArticle} from 'src/app/models/menu-article.model';

export class LunchArticleDTO extends Serializable {
    title: string;
    price: number;
    lunchCategories: LunchCategory[] = [];
    article: Article;
    subheader: string;

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('lunchCategories', input, LunchCategory);
        this.deserializeComplexType('article', input, Article);
        return this;
    }
}

export class LunchArticle extends LunchArticleDTO {
    checked = false;

    get additionalArticles(): Array<MenuArticle> {
        const ret = [];
        this.lunchCategories.forEach(lunchMenuCategory => {
            lunchMenuCategory.checkedLunchArticles.forEach((menuArticle: MenuArticle) => {
                ret.push(menuArticle);
            });
        });
        return ret;
    }
}
