import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Keyboard} from '@capacitor/keyboard';

@Component({
    selector: 'app-modal-comment-crud',
    templateUrl: './modal-comment-crud.component.html',
    styleUrls: ['./modal-comment-crud.component.scss'],
})
export class ModalCommentCRUDComponent implements OnInit {
    @Input() comment = '';

    public hiding = false;

    constructor(
        private modalController: ModalController
    ) {
    }

    ngOnInit() {
    }

    async save() {
        this.hiding = true;
        try {
            await Keyboard.hide();
        } catch (e) {
        }
        setTimeout(async () => {
            await this.modalController.dismiss({comment: this.comment});
            this.hiding = false;
        }, 250);
    }

    async dismiss() {
        this.hiding = true;
        try {
            await Keyboard.hide();
        } catch (e) {
        }
        setTimeout(async () => {
            await this.modalController.dismiss();
            this.hiding = false;
        }, 250);
    }

    async delete() {
        this.comment = '';
    }
}
