<ion-row *ngIf="checkoutBasketItem">
    <ion-col size="6">
        <ion-row>
            <ion-col size="12" size-lg="8">
                <div *ngIf="checkoutBasketItem.type !== 2"><b>{{ checkoutBasketItem.title }}</b></div>
                <div *ngIf="checkoutBasketItem.type === 2"><b>{{ checkoutBasketItem.title }}</b></div>
                <div *ngIf="checkoutBasketItem.additionalArticles?.length" style="font-size: 13px;">
                    <ng-container *ngFor="let menuArticle of checkoutBasketItem.additionalArticles">
                        <div style="font-size: 12px">+ {{ menuArticle.title }}</div>
                    </ng-container>
                </div>
            </ion-col>
            <ion-col size="12" size-lg="4">
                <div style="margin-top: 5px">
                    <span style="color: var(--ion-color-primary)">
                        <ng-container
                                *ngIf="!(checkoutBasketItem?.specialPrice && checkoutBasketItem?.specialPriceValidFrom && checkoutBasketItem?.specialPriceValidUntil)">
                            <b>{{ checkoutBasketItem?.priceWithoutModifiers| currency: 'EUR' }}</b>
                        </ng-container>
                        <ng-container
                                *ngIf="checkoutBasketItem?.specialPrice && checkoutBasketItem?.specialPriceValidFrom && checkoutBasketItem?.specialPriceValidUntil">
                            <s>{{ checkoutBasketItem?.priceWithoutModifiers | currency: 'EUR' }}</s>
                            &nbsp;<b>{{ checkoutBasketItem?.specialPriceWithoutModifiers | currency: 'EUR' }}</b>
                        </ng-container>
                    </span>
                    <span style="font-size: small"> pro {{ checkoutBasketItem.unit.label }}</span>
                </div>
            </ion-col>
        </ion-row>

        <!---Attributes-->
        <ng-container *ngIf="checkoutBasketItem.articleAttributes">
            <ion-row *ngFor="let attribute of checkoutBasketItem.articleAttributes">
                <ion-col size="6" size-lg="8" style="padding-bottom: 0; padding-top: 0;">
                    <b style="font-size: small">{{ attribute.title }}</b>
                </ion-col>
                <ion-col size="6" size-lg="4" style="padding-bottom: 0; padding-top: 0;">
                    <div style="margin-top: 5px">
                        <div style="margin-top: 5px">
                        <span style="color: var(--ion-color-primary)">
                            <b>+{{ attribute?.additionalPrice | currency: 'EUR' }}</b>
                        </span>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
        </ng-container>

        <ion-row *ngIf="checkoutBasketItem.comment">
            <ion-col size="8">
                <div style="margin-top: 5px">
                    <div style="font-size: x-small; font-style: italic; line-height: 1">{{ checkoutBasketItem.comment }}</div>
                </div>
            </ion-col>
        </ion-row>
    </ion-col>
    <ion-col size="6" class="ion-padding-horizontal">
        <ion-row class="ion-align-items-center">
            <ion-col class="ion-text-center" size="4">
                <ng-container *ngIf="!checkoutBasketItem.isDecreasable">
                    <ion-button size="small" expand="full" class="ion-no-margin"
                                (click)="removeArticleFromBasket(checkoutBasketItem)">
                        <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>
                </ng-container>
                <ng-container *ngIf="checkoutBasketItem.isDecreasable">
                    <ion-button size="small" expand="full" class="ion-no-margin"
                                (click)="decreaseAmount(checkoutBasketItem)">
                        <ion-icon name="remove-outline"></ion-icon>
                    </ion-button>
                </ng-container>
            </ion-col>
            <ion-col class="ion-text-center" size="4">
                <span style="font-size: x-small">{{ checkoutBasketItem.amount }}  {{ checkoutBasketItem.sellUnit.label }}</span>
            </ion-col>
            <ion-col class="ion-text-center" size="4">
                <ion-button size="small" expand="full" class="ion-no-margin"
                            [disabled]="!checkoutBasketItem.isIncreasable"
                            (click)="increaseAmount(checkoutBasketItem)">
                    <ion-icon name="add-outline"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-button (click)="editExtras(checkoutBasketItem)"
                            class="ion-no-margin btn-secondary-outline" size="small" color="secondary"
                            fill="outline">
                    <ion-icon name="create-outline"></ion-icon>
                    Extras anpassen
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-button size="small" color="secondary" expand="full" class="ion-no-margin"
                            (click)="addNote(checkoutBasketItem)">
                    <ion-icon name="create-outline"></ion-icon>
                    Anmerkung
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="checkoutBasketItem?.minItems > 1" class="ion-no-padding">
            <ion-col class="ion-text-center ion-no-padding">
                <span style="font-size: 8px">Mindestbestellmenge von {{ checkoutBasketItem?.minItems }} {{ checkoutBasketItem.sellUnit.label }}</span>
            </ion-col>
        </ion-row>
    </ion-col>
    <ion-col size="12">
        <hr/>
    </ion-col>
</ion-row>
