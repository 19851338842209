import {Serializable} from './serializable.abstract';
import {LunchArticle} from './lunch-article.model';

export class LunchDayDTO extends Serializable {
    title: string;
    expired: boolean;
    weekDay: number;
    date: string;
    lunchArticles: LunchArticle[];

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('lunchArticles', input, LunchArticle);
        return this;
    }
}
export class LunchDay extends LunchDayDTO {
}
