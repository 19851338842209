<ion-header [translucent]="true">
    <ion-toolbar>
        <ion-title>
            Update verfügbar
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">Update verfügbar</ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-grid>
        <ion-row>
            <ion-col class="ion-text-center">
                <i class="far fa-sync" style="color: var(--ion-color-primary); font-size: 100px; margin: 100px 0"></i>
                <p *ngIf="breaking">Ein neues Update steht für Sie zum Download bereit. Um die App weiterhin nutzen zu können, installieren Sie bitte das Update.</p>
                <p *ngIf="!breaking">Ein neues Update steht für Sie zum Download bereit. Ihre aktuell installierte App Version ist veraltet. Bitte installieren Sie das Update um von den neuen Funktionen der App profitieren zu können.</p>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button expand="full" color="primary" (click)="openMarket()" [disabled]="appStoreLoading">Update installieren</ion-button>
                    <ion-button expand="full" color="secondary" *ngIf="!breaking" (click)="dismiss()">Später nachfragen</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
