import {Serializable} from './serializable.abstract';

export class UnitDefinitionDTO extends Serializable {
  id: number;
  label: string;
  amount: number;
  unit: string;
}

export class UnitDefinition extends UnitDefinitionDTO {
}
