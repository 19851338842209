import {Serializable} from './serializable.abstract';

export class UserDTO extends Serializable {
    id: number;
    companyName: string;
    firstName: string;
    lastName: string;
    street: string;
    zip: string;
    location: string;
    email: string;
    phone: string;
    branch: number;

    serialize(): any {
        return {
            companyName: this.companyName,
            firstName: this.firstName,
            lastName: this.lastName,
            street: this.street,
            zip: this.zip,
            location: this.location,
            email: this.email,
            phone: this.phone,
            branch: this.branch ?? 0
        };
    }
}

export class User extends UserDTO {
    get isValid(): boolean {
        return !!(this.firstName && this.lastName && this.street && this.zip && this.location && this.email && this.phone);
    }
}
