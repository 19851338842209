import {Component} from '@angular/core';
import {DateService} from '../../services/util/date.service';
import {UserService} from '../../services/user.service';
import {ClientService} from '../../services/client.service';

@Component({
    selector: 'app-user-salutation',
    templateUrl: './user-salutation.component.html',
    styleUrls: ['./user-salutation.component.scss'],
})
export class UserSalutationComponent {

    constructor(
        public dateService: DateService,
        public userService: UserService,
        public clientService: ClientService
    ) {
    }
}
