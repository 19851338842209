import {Serializable} from './serializable.abstract';
import {OrderItemType} from '../enums/order-item-type.enum';
import {MenuArticle} from './menu-article.model';
import {ArticleAttribute} from "./article-attribute.model";

export class BaseBasketItemDTO extends Serializable {
    amount = 0;
    type: OrderItemType;
    additionalArticles: MenuArticle[] = [];
    articleAttributes: ArticleAttribute[] = []
    title = '';
    unit;
    sellUnit;

    get hash(): string {
        // Start with the object's id and type
        let hash = `${this.id}_${this.type}`;

        // Sort and append additionalArticles information to the hash
        const sortedAdditionalArticles = this.additionalArticles.sort((a, b) => a.menuCategory - b.menuCategory);
        sortedAdditionalArticles.forEach((menuArticle: MenuArticle) => {
            hash += `_${menuArticle.menuCategory}-${menuArticle.id}`;
        });

        // Sort and append articleAttributes information to the hash
        const sortedArticleAttributes = this.articleAttributes.sort((a, b) => a.id - b.id);
        sortedArticleAttributes.forEach((attribute: ArticleAttribute) => {
            hash += `_${attribute.id}`;
        });

        return hash;
    }

    deserialize(input: any): Serializable {
        super.deserialize(input);

        this.deserializeComplexArray('additionalArticles', input, MenuArticle);
        this.deserializeComplexArray('articleAttributes', input, ArticleAttribute);

        return this;
    }
}
