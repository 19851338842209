import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {DashboardPage} from './pages/dashboard/dashboard.page';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'basket',
        loadChildren: () => import('./pages/basket/basket.module').then(m => m.BasketPageModule)
    },
    {
        path: 'order',
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule)
    },
    {
        path: 'initialization',
        loadChildren: () => import('./pages/initialization/initialization.module').then(m => m.InitializationPageModule)
    },
    {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
    },
    {
        path: 'dashboard',
        component: DashboardPage
    },
    {
        path: 'products',
        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule)
    },
    {
        path: 'more',
        loadChildren: () => import('./pages/more/more.module').then(m => m.MorePageModule)
    },
    {
        path: 'menus',
        loadChildren: () => import('./pages/menus/menus.module').then(m => m.MenusPageModule)
    },
    {
        path: '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
