import {Serializable} from './serializable.abstract';
import {BaseArticle} from './base-article.model';

export class MenuArticleDTO extends Serializable {
    title = '';
    additionalPrice = 0;
    article: BaseArticle = null;
    menuCategory: number;

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexType('article', input, BaseArticle);
        return this;
    }

    serialize(): any {
        return {
            article: this.article.id,
            category: this.menuCategory
        };
    }
}

export class MenuArticle extends MenuArticleDTO {
    checked = false;
}
