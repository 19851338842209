import {Serializable} from './serializable.abstract';

export class BranchDTO extends Serializable {
    title = '';
    street = '';
    zip = '';
    city = '';
    nonOpeningDays: Date[];
    nonDeliveryDays: Date[];
    pickupTimes: [{ day: number, hours: number[], minutes: number[], summary: string[] }];

    public deserialize(input: any): Serializable {
        return super.deserialize(input);
    }
}

export class Branch extends BranchDTO {


}
