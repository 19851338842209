<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>
      App deaktiviert
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">App deaktiviert</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <i class="fal fa-store-alt-slash" style="color: var(--ion-color-primary); font-size: 100px; margin: 100px 0"></i>
        <h1>{{clientService.client?.availability?.title}}</h1>
        <p>{{clientService.client?.availability?.description}}</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
