import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Article} from '../../models/article.model';
import {ModalController, ToastController} from '@ionic/angular';
import {MenuArticle} from '../../models/menu-article.model';
import {MenuCategory} from '../../models/menu-category.model';
import {MatStepper} from '@angular/material/stepper';
import {BasketService} from '../../services/basket.service';
import {LoadingService} from '../../services/util/loading.service';
import {Haptics} from '@capacitor/haptics';
import {ModalCommentCRUDComponent} from '../modal-comment-crud/modal-comment-crud.component';
import {OrderItemType} from '../../enums/order-item-type.enum';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {MenuService} from 'src/app/services/menu.service';

@Component({
    selector: 'app-modal-menu-configuration',
    templateUrl: './modal-menu-configuration.component.html',
    styleUrls: ['./modal-menu-configuration.component.scss'],
})
export class ModalMenuConfigurationComponent implements OnInit {
    @Input() article: Article = null;
    @Input() amount = 0;

    public comment = '';

    public isLastPage = false;

    @ViewChild('menuConfigurationStepper') public menuConfigurationStepper: MatStepper;

    constructor(
        public modalCtrl: ModalController,
        private basketService: BasketService,
        private menuService: MenuService,
        private loadingService: LoadingService,
        private toastController: ToastController,
        private modalController: ModalController,
    ) {
    }

    ngOnInit() {
        if (!this.amount) {
            this.amount = this.article.clickAmount;
        }
        this.article.menuCategories.forEach(async (menuCategory: MenuCategory) => {
            menuCategory.menuArticles = await this.menuService.getArticlesOfMenuCategory(menuCategory.id);
            menuCategory.uncheckMenuArticles();
        });
    }

    public addToMenu(menuCategory: MenuCategory, menuArticle: MenuArticle) {
        if (menuArticle.checked) {
            menuCategory.uncheckMenuArticles();
            return;
        }
        menuCategory.uncheckMenuArticles();
        menuArticle.checked = true;
    }

    public selectionChanged($event: StepperSelectionEvent) {
        if ($event.selectedIndex === this.menuConfigurationStepper.steps.length - 1) {
            this.isLastPage = true;
            return;
        }
        this.isLastPage = false;
    }

    public async addMenuToBasket() {
        await this.loadingService.createLoading();
        this.basketService.addMenuToBasket(
            this.article.id,
            this.article,
            this.article.additionalArticles,
            this.amount,
            this.comment,
            OrderItemType.MENU_ARTICLE
        );
        await this.loadingService.dismissLoading();
        const toast = await this.toastController.create({
            message: 'Menü zum Warenkorb hinzugefügt',
            duration: 2000,
            color: 'secondary',
            position: 'top',
        });
        await Haptics.vibrate({duration: 200});
        await this.modalCtrl.dismiss();
        await toast.present();
    }

    public decreaseAmount() {
        if (this.amount <= this.article.clickAmount) {
            return;
        }
        this.amount = this.amount - this.article.clickAmount;
    }

    public async increaseAmount() {
        this.amount += this.article.clickAmount;
    }

    public async addNoteToMenu() {
        const modal = await this.modalController.create({
            component: ModalCommentCRUDComponent,
            componentProps: {
                comment: this.comment
            }
        });

        await modal.present();

        modal.onWillDismiss().then(
            (response: { data }) => {
                if (response.data) {
                    this.comment = response.data.comment;
                }
            }
        );
    }
}
