<div *ngIf="categories">
  <ion-list>
    <ng-container *ngFor="let category of categories">
      <ion-item lines="none">
        <ion-label>
          <p style="font-size: 15px; letter-spacing: 0.3px; font-weight: 500; color: #3D3534">{{category.title}}</p>
        </ion-label>
      </ion-item>
    </ng-container>
  </ion-list>
</div>
