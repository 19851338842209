import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {ClientService} from '../../services/client.service';
import {Subscription} from 'rxjs';
import {AppUpdate} from "@capawesome/capacitor-app-update";

@Component({
    selector: 'app-modal-update',
    templateUrl: './modal-update.component.html',
    styleUrls: ['./modal-update.component.scss'],
})
export class ModalUpdateComponent implements OnInit, OnDestroy {
    @Input() breaking = true;

    public appStoreLoading = false;

    private subscription: Subscription;

    constructor(
        private modalController: ModalController,
        private platform: Platform,
        private clientService: ClientService
    ) {
    }

    ngOnInit() {
        this.subscription = this.platform.backButton.subscribeWithPriority(101, async () => {

        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public async dismiss() {
        await this.modalController.dismiss();
    }

    public async openMarket() {
        this.appStoreLoading = true;
        await AppUpdate.openAppStore();
        this.appStoreLoading = false;
    }
}
