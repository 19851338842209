<ion-app>
    <ng-container *ngIf="environment.desktop">
        <div style="z-index: 1" id="navbar-desktop">
            <ion-grid>
                <ion-row>
                    <ion-col push-xl="2" size-xl="8" class="ion-no-padding">
                        <ion-row>
                            <ion-col size-xl="6">
                                <div style="display: flex; justify-content: flex-start">
                                    <div class="navbar-link" [routerLink]="'/'">
                                        <ion-icon name="home-outline"></ion-icon>
                                    </div>
                                </div>
                            </ion-col>
                            <ion-col size-xl="6">
                                <div style="display: flex; justify-content: flex-end">
                                    <div class="navbar-link" [routerLink]="['/products']">
                                        <ion-icon name="restaurant-outline"></ion-icon>
                                    </div>
                                    <div class="navbar-link" [routerLink]="['/more/about']">
                                        <ion-icon name="information-circle-outline"></ion-icon>
                                    </div>
                                    <div class="navbar-link" [routerLink]="['/more/profile']" >
                                        <ion-icon name="person-outline"></ion-icon>
                                    </div>
                                    <div class="navbar-link" [routerLink]="['/basket']">
                                        <ion-icon name="basket-outline"></ion-icon>
                                        <ion-badge
                                                *ngIf="basketService.basket?.items?.length > 0">{{basketService.basket?.items?.length}}</ion-badge>
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </ng-container>
    <ion-router-outlet id="content1" [style.margin-top]="environment.desktop ? '' : '0px'" [class.desktop]="environment.desktop"></ion-router-outlet>
    <ng-container *ngIf="!environment.desktop">
        <ion-tabs id="tabs" style="position: relative">
            <ion-tab-bar slot="bottom">
                <ng-container *ngFor="let pages of navigate">
                    <ion-tab-button [tab]="pages.url">
                        <ion-icon [name]="pages.icon"></ion-icon>
                        <!--<ion-label>{{pages.title}}</ion-label>-->
                        <ion-badge
                                *ngIf="pages.title == 'Warenkorb' && basketService.basket?.items?.length > 0">{{basketService.basket?.items?.length}}</ion-badge>
                        <ion-badge *ngIf="pages.url == 'notifications' && notificationService.unseenNotifications.length > 0">
                            <ng-container *ngIf="notificationService.unseenNotifications.length <= 9">
                                {{notificationService.unseenNotifications.length}}
                            </ng-container>
                            <ng-container *ngIf="notificationService.unseenNotifications.length > 9">
                                9+
                            </ng-container>
                        </ion-badge>
                    </ion-tab-button>
                </ng-container>
            </ion-tab-bar>
        </ion-tabs>
    </ng-container>
</ion-app>
