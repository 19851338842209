import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController, ToastController} from '@ionic/angular';
import {MatStepper} from '@angular/material/stepper';
import {LunchArticle} from '../../models/lunch-article.model';
import {LunchDay} from '../../models/lunch-day.model';
import {ClientService} from '../../services/client.service';
import {MenuArticle} from '../../models/menu-article.model';
import {ModalCommentCRUDComponent} from '../modal-comment-crud/modal-comment-crud.component';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {LunchCategory} from '../../models/lunch-category.model';
import {LoadingService} from '../../services/util/loading.service';
import {BasketService} from '../../services/basket.service';
import {Haptics} from '@capacitor/haptics';
import {OrderItemType} from '../../enums/order-item-type.enum';
import {MenuService} from '../../services/menu.service';

@Component({
    selector: 'app-modal-lunch-menu-configuration',
    templateUrl: './modal-lunch-menu-configuration.component.html',
    styleUrls: ['./modal-lunch-menu-configuration.component.scss'],
})
export class ModalLunchMenuConfigurationComponent implements OnInit {
    @Input() lunchArticle: LunchArticle = null;
    @Input() lunchDay: LunchDay = null;
    @Input() amount: number;
    @ViewChild('menuConfigurationStepper') public menuConfigurationStepper: MatStepper;

    public isLastPage = false;
    public fullPrice = 0;
    public menuPrice = 0;
    public comment = '';

    constructor(
        public modalCtrl: ModalController,
        public clientService: ClientService,
        private loadingService: LoadingService,
        public basketService: BasketService,
        private menuService: MenuService,
        private toastController: ToastController
    ) {
    }

    ngOnInit() {
        this.menuPrice = this.lunchArticle.price;
        this.fullPrice = this.menuPrice;
        this.amount = this.lunchArticle.article.clickAmount;
        if (this.lunchArticle.lunchCategories) {
            this.lunchArticle.lunchCategories.forEach(async lunchCategory => {
                lunchCategory.menuArticles = await this.menuService.getArticlesOfMenuCategory(lunchCategory.id);

                lunchCategory.uncheckLunchArticles();
            });
        }
    }

    public selectionChanged($event: StepperSelectionEvent) {
        if ($event.selectedIndex === this.menuConfigurationStepper.steps.length - 1) {
            this.isLastPage = true;
            return;
        }
        this.isLastPage = false;
    }

    public addToMenu(lunchCategory: LunchCategory, menuArticle: MenuArticle) {
        if (menuArticle.checked) {
            lunchCategory.uncheckLunchArticles();
            this.fullPrice = this.menuPrice;
            return;
        }
        lunchCategory.uncheckLunchArticles();
        menuArticle.checked = true;
        this.menuPrice += menuArticle.additionalPrice;
        this.fullPrice = this.menuPrice;
    }

    public decreaseAmount() {
        if (this.amount <= this.lunchArticle.article.clickAmount) {
            return;
        }
        this.amount = this.amount - this.lunchArticle.article.clickAmount;
        this.fullPrice = this.menuPrice * this.amount;
    }

    public async increaseAmount() {
        this.amount += this.lunchArticle.article.clickAmount;
        this.fullPrice = this.menuPrice * this.amount;
    }

    public async addNoteToMenu() {
        const modal = await this.modalCtrl.create({
            component: ModalCommentCRUDComponent,
            componentProps: {
                comment: this.comment
            }
        });

        await modal.present();

        modal.onWillDismiss().then(
            (response: { data }) => {
                if (response.data) {
                    this.comment = response.data.comment;
                }
            }
        );
    }

    public async addLunchMenuToBasket() {
        await this.loadingService.createLoading();
        this.basketService.addMenuToBasket(
            this.lunchArticle.id,
            this.lunchArticle.article,
            this.lunchArticle.additionalArticles,
            this.amount,
            this.comment,
            OrderItemType.LUNCH_ARTICLE,
            this.lunchDay
        );
        await this.loadingService.dismissLoading();
        const toast = await this.toastController.create({
            message: 'Menü zum Warenkorb hinzugefügt',
            duration: 2000,
            color: 'secondary',
            position: 'top',
        });
        await Haptics.vibrate({duration: 200});
        await this.modalCtrl.dismiss();
        await toast.present();
    }
}
