import {Component, Input} from '@angular/core';
import {ClientService} from '../../services/client.service';
import {environment} from 'src/environments/environment';
import {ImageService} from '../../services/util/image.service';

@Component({
    selector: 'app-header-banner',
    templateUrl: './header-banner.component.html',
    styleUrls: ['./header-banner.component.scss'],
})
export class HeaderBannerComponent {
    public environment = environment;

    @Input() hasHeader = false;

    constructor(
        public clientService: ClientService,
        public imageService: ImageService
    ) {
    }
}
