import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {Category} from '../models/category.model';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    get selectedCategory(): Category {
        return this._selectedCategory;
    }

    set selectedCategory(value: Category) {
        this._selectedCategory = value;
    }

    get selectedSegment(): number {
        if (this._selectedCategory) {
            return this._selectedCategory.id;
        } else {
            return 0;
        }
    }

    private _selectedCategory: Category;

    constructor(
        private dataService: DataService
    ) {
    }


    public getCategories(): Promise<Category[]> {
        return this.dataService.getMultiple('/category', Category).toPromise();
    }

    public getCategory(id: number): Promise<Category> {
        return this.dataService.get<Category>('/category/' + id).toPromise();
    }
}
