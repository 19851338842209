import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Article} from '../models/article.model';
import {DataService} from './data.service';
import {Day} from '../enums/day.enum';
import {environment} from '../../environments/environment';
import {Allergen, AllergenDTO} from '../models/allergen';


@Injectable({
    providedIn: 'root'
})
export class ArticleService {

    public allArticles: Article[] = [];

    constructor(
        private dataService: DataService
    ) {
    }

    public async getArticles(category = 0, search = '', allergens = ''): Promise<Article[]> {
        const params = {};
        if (category) {
            Object.assign(params, {category});
        }
        if (search) {
            Object.assign(params, {search});
        }
        if (allergens) {
            Object.assign(params, {allergens});
        }
        return await this.dataService.getMultiple('/article', Article, params).toPromise();
    }

    public async getTopArticles(): Promise<Article[]> {
        return await this.dataService.getMultiple('/article/top', Article).toPromise();
    }

    public async getFeaturedArticles(): Promise<Article[]> {
        return await this.dataService.getMultiple('/article/featured', Article).toPromise();
    }

    /**
     * This method returns an article with the given id.
     * @param id the id of the article
     */
    public async getArticle(id: number): Promise<Article> {
        return await this.dataService.get<Article>('/article/' + id.toString(10))
            .pipe(
                map(response => {
                    return new Article().deserialize(response) as Article;
                })
            )
            .toPromise();
    }

    getAvailableDays(article: Article): string {
        const days: number[] = article.availableDays;

        if (!days.length) {
            return '';
        }

        const consecutiveRanges: string[] = [];
        let startDay: number = days[0];
        let endDay: number = days[0];

        for (let i = 1; i < days.length; i++) {
            if (days[i] === days[i - 1] + 1) {
                endDay = days[i];
            } else {
                consecutiveRanges.push(this.formatDayRange(startDay, endDay));
                startDay = days[i];
                endDay = days[i];
            }
        }

        consecutiveRanges.push(this.formatDayRange(startDay, endDay));

        return consecutiveRanges.join(', ');
    }

    formatDayRange(startDay: number, endDay: number): string {
        if (startDay === endDay) {
            return Day[startDay];
        } else {
            return Day[startDay] + '-' + Day[endDay];
        }
    }


    public getAllergens(): Promise<Allergen[]> {
        return this.dataService.get('/allergen', environment.clientId)
            .pipe(
                map((response: any[]) => {
                    const allergens = [];

                    response.forEach(item => {
                        allergens.push(new AllergenDTO().deserialize(item) as Allergen);
                    });

                    return allergens;
                })
            ).toPromise();
    }
}
