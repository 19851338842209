import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Article} from '../../models/article.model';
import {BaseArticle} from '../../models/base-article.model';
import {LoadingService} from '../../services/util/loading.service';
import {ArticleService} from '../../services/article.service';
import {BasketService} from '../../services/basket.service';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {ClientService} from '../../services/client.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ImageService} from '../../services/util/image.service';
import {environment} from 'src/environments/environment';
import {Image} from '../../models/image.model';

@Component({
    selector: 'app-article-card-item-horizontal',
    templateUrl: './article-card-item-horizontal.component.html',
    styleUrls: ['./article-card-item-horizontal.component.scss'],
})
export class ArticleCardItemHorizontalComponent implements OnInit {
    @Input() article: Article | BaseArticle;
    @Input() title = '';
    @Input() showBasketAmount = true;
    @Input() overridePriceSection = '';
    @Input() cols = 0;

    @HostBinding('attr.style')
    public get valueAsStyle(): any {
        return this.sanitizer.bypassSecurityTrustStyle(`--productCols: ${this.cols ? this.cols : this.dataService.productCols}`);
    }

    public articleImage: Image;

    amount: number;

    public environment = environment;

    constructor(
        private loadingService: LoadingService,
        private articleService: ArticleService,
        public basketService: BasketService,
        private router: Router,
        public dataService: DataService,
        public clientService: ClientService,
        private sanitizer: DomSanitizer,
        private imageService: ImageService
    ) {
    }

    async ngOnInit() {
        if (!this.article) {
            return;
        }

        if (this.article.thumbnail) {
            this.articleImage = this.article.thumbnail;
        } else if (this.article.images && this.article.images.length > 0) {
            this.articleImage = this.article.images[0];
        }

        this.amount = this.getCount(this.article);
        this.basketService.onBasketChange.subscribe((article: Article) => {
            this.amount = this.getCount(this.article);
        });
    }

    public getCount(article: Article | BaseArticle) {
        const orderArticle = this.basketService.findArticleWithIdInBasket(article.id);
        if (orderArticle) {
            return orderArticle.amount;
        }
        return 0;
    }
}
