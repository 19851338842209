import {BaseBasketItemDTO} from './base-basket-item.model';

export class BasketItemDTO extends BaseBasketItemDTO {

}

export class BasketItem extends BasketItemDTO {
    public lunchDay: { date: string, weekDay: number } = null;
    private _comment = '';

    get comment(): string {
        return this._comment;
    }

    set comment(comment) {
        if (!comment) {
            this._comment = '';
            return;
        }
        if (!this.comment) {
            this._comment = comment;
        } else {
            this._comment += ', ' + comment;
        }
    }

    public overrideComment(comment = '') {
        this._comment = comment;
    }
}
