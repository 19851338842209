import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Serializable} from '../models/serializable.abstract';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public readonly baseUrl;
    public readonly apiUrl;

    public width: number;
    public height: number;
    public maxCharacters = 50;
    public productCols = 2;

    constructor(
        private http: HttpClient,
        private platform: Platform
    ) {
        this.apiUrl = environment.apiUrl;

        const currentUrl = window.location.href;
        if (currentUrl.includes('pilot') && environment?.apiUrlPilot) {
            this.apiUrl = environment?.apiUrlPilot;
        }

        this.width = this.platform.width();
        this.height = this.platform.height();

        if (this.width >= 992) {
            this.productCols = 3;
            this.maxCharacters = 50;
        }
        if (this.width >= 1200) {
            this.productCols = 6;
        }
        if (this.width >= 1200 && this.width <= 1500) {
            this.maxCharacters = 32;
        }

        window.onresize = () => {
            this.width = this.platform.width();
            this.height = this.platform.height();

            this.productCols = 2;
            this.maxCharacters = 50;

            if (this.width >= 992) {
                this.productCols = 3;
                this.maxCharacters = 50;
            }
            if (this.width >= 1200) {
                this.productCols = 6;
            }
            if (this.width >= 1200 && this.width <= 1500) {
                this.maxCharacters = 32;
            }
        };
    }

    public get<T>(route: string, params = {}): Observable<T> {
        return this.http.get<T>(this.apiUrl + route, {params});
    }

    public post<T>(route: string, params = {}, body: any | null): Observable<T> {
        return this.http.post<T>(this.apiUrl + route, body, {params});
    }

    public put<T>(route: string, body: any | null = null, params: HttpParams = null): Observable<T> {
        return this.http.put<T>(this.apiUrl + route, body, {params});
    }

    public getMultiple<DTO extends Serializable, T extends DTO>(
        path: string,
        type: new() => T,
        params = {}
    ): Observable<T[]> {
        return this.get<DTO[]>(path, params)
            .pipe(
                map(data => {
                    const items: T[] = [];
                    data.forEach(item => {
                        items.push(new type().deserialize(item) as T);
                    });
                    return items;
                })
            );
    }
}
