import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './components/app/app.component';
import {AppRoutingModule} from './app-routing.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {RequestInterceptor} from './interceptors/request.interceptor';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModalUpdateComponent} from './modals/modal-update/modal-update.component';
import {ModalAvailabilityComponent} from './modals/modal-availability/modal-availability.component';
import {ModalCommentCRUDComponent} from './modals/modal-comment-crud/modal-comment-crud.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StripeService} from './services/util/stripe.service';
import {ModalMenuConfigurationComponent} from './modals/modal-menu-configuration/modal-menu-configuration.component';
import {MatStepperModule} from '@angular/material/stepper';
import {ComponentsModule} from './components/components.module';
import {DashboardPage} from './pages/dashboard/dashboard.page';
import {ModalNoConnectionComponent} from './modals/modal-no-connection/modal-no-connection.component';
import {CategorySelectComponent} from './modals/category-select/category-select.component';
import {
    ModalLunchMenuConfigurationComponent
} from './modals/modal-lunch-menu-configuration/modal-lunch-menu-configuration.component';
import {OrderDetailPage} from './modals/order-detail/order-detail.page';
import {MenusPageModule} from './pages/menus/menus.module';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {environment} from '../environments/environment';
import {ModalSelectBranchComponent} from './modals/modal-select-branch/modal-select-branch.component';
import {ProductExtrasModal} from "./modals/product-extras-modal/product-extras-modal";
import {SharedModule} from "./shared.module";
import {register} from "swiper/element/bundle";

registerLocaleData(localeDe, 'de');

const config = {};

if (environment.desktop) {
    Object.assign(config, {mode: 'ios'});
}

register();

@NgModule({ declarations: [AppComponent, ModalUpdateComponent, ModalAvailabilityComponent, ModalCommentCRUDComponent, ModalMenuConfigurationComponent, ModalNoConnectionComponent, ModalSelectBranchComponent, DashboardPage, CategorySelectComponent, ModalLunchMenuConfigurationComponent, OrderDetailPage, ProductExtrasModal],
    bootstrap: [AppComponent], imports: [BrowserModule, IonicModule.forRoot(config), AppRoutingModule, BrowserAnimationsModule, FormsModule, MatStepperModule, ComponentsModule, MenusPageModule, ReactiveFormsModule, SharedModule], providers: [
        DatePipe,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true
        },
        StripeService,
        {
            provide: LOCALE_ID,
            useValue: 'de'
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
