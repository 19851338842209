import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {Branch} from '../models/branch.model';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BranchService {
    get selectedBranch(): Branch {
        return this._selectedBranch;
    }

    set selectedBranch(value: Branch) {
        if (value) {
            localStorage.setItem('branch', JSON.stringify(value.id));
        } else {
            localStorage.removeItem('branch');
        }
        this._selectedBranch = value;
    }

    private _selectedBranch: Branch = null;
    public branches: Branch[] = [];

    constructor(
        private dataService: DataService
    ) {
    }

    public getBranches() {
        return this.dataService.get<Branch[]>('/branch')
            .pipe(
                map(response => {
                    const branches = [];
                    response.forEach((branch, i) => {
                        branch.pickupTimes.forEach((pickupTime, j) => {
                            response[i].pickupTimes[j].day = pickupTime.day === 6 ? 0 : pickupTime.day + 1;
                        });
                        branches.push(new Branch().deserialize(response[i]) as Branch);
                    });

                    const storedBranch = localStorage.getItem('branch');

                    if (storedBranch) {
                        const favoriteBranch = branches.find(branch => branch.id === parseInt(storedBranch, 10));
                        if (favoriteBranch) {
                            this.selectedBranch = favoriteBranch;
                        } else {
                            this.selectedBranch = null;
                        }
                    } else {
                        this.selectedBranch = null;
                    }

                    return branches;
                })
            )
            .toPromise();
    }
}
