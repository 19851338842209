<ion-header>
    <ion-toolbar color="primary">
        <ion-title>Details zur Bestellung</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col size="8">
                <div class="desiredPickupTime">
                    {{order.desiredPickupTime | date: 'dd.MM.yyyy'}} um {{order.desiredPickupTime | date: ' HH:mm'}}
                </div>
                <div class="desiredPickupTime__label">
                    {{order.delivery ? 'Lieferzeitpunkt' : 'Abholzeitpunkt'}}
                </div>
            </ion-col>
            <ion-col size="4">
                <div class="status">
                    <span *ngIf="order?.status == 0" style="color: orange">Offen</span>
                    <span *ngIf="order?.status == 1" style="color: green">Bestätigt</span>
                    <span *ngIf="order?.status == 2" style="color: grey">Abgeschlossen</span>
                </div>
            </ion-col>
            <ion-col size="12">
                <div class="desiredPickupTime">
                    <ng-container [ngSwitch]="order.paymentType">
                        <ng-container *ngSwitchCase="PaymentType.TAKE_AWAY">Bei Abholung</ng-container>
                        <ng-container *ngSwitchCase="PaymentType.STRIPE">Kreditkarte</ng-container>
                        <ng-container *ngSwitchCase="PaymentType.DELIVERY_CASH">Barzahlung bei Lieferung</ng-container>
                        <ng-container *ngSwitchCase="PaymentType.DELIVERY_CARD">Kartenzahlung bei Lieferung
                        </ng-container>
                        <ng-container *ngSwitchDefault>Bei Abholung</ng-container>
                    </ng-container>
                </div>
                <div class="desiredPickupTime__label">
                    Zahlungsmethode
                </div>
            </ion-col>
            <ion-col *ngIf="order.deliveryInstructions" size="12">
                <div class="desiredPickupTime">
                    {{ order.deliveryInstructions }}
                </div>
                <div class="desiredPickupTime__label">
                    Lieferanmerkung
                </div>
            </ion-col>
            <ion-col size="12">
                <div class="desiredPickupTime">
                    <ng-container *ngIf="order.grossSum !== 0">
                        {{ order.grossSum | currency:'EUR' }}
                    </ng-container>
                    <ng-container *ngIf="order.grossSum === 0">
                        nicht berechenbar
                    </ng-container>
                </div>
                <div class="desiredPickupTime__label">
                    Gesamtsumme
                </div>
            </ion-col>
            <ng-container *ngFor="let orderItem of order?.articles">
                <ion-col size="12">
                    <div class="checkoutBasketItem">
                        <ion-row class="ion-align-items-center">
                            <ion-col size="2">
                                <div class="checkoutBasketItem__amount">
                                    {{orderItem.count}}
                                </div>
                                <div class="checkoutBasketItem__sellUnit">
                                    {{ orderItem.sellUnit.label }}
                                </div>

                            </ion-col>
                            <ion-col size="7">
                                <div class="checkoutBasketItem__title">
                                    {{orderItem.title}}
                                </div>
                                <div class="checkoutBasketItem__menuArticles"
                                     *ngIf="orderItem.articleAttributes">
                                    <ng-container
                                            *ngFor="let articleAttribute of orderItem.articleAttributes">
                                        {{ articleAttribute.title }}<br/>
                                    </ng-container>
                                </div>
                                <div class="checkoutBasketItem__menuArticles" *ngIf="orderItem.additionalArticles">
                                    <ng-container *ngFor="let menuArticle of orderItem.additionalArticles">
                                        + {{menuArticle.title}}<br/>
                                    </ng-container>
                                </div>
                            </ion-col>
                            <ion-col size="3">
                                <div class="checkoutBasketItem__price">
                                    {{ (orderItem.pricePerArticle) | currency: 'EUR' }}
                                </div>
                                <div class="checkoutBasketItem__priceUnit">
                                    pro {{orderItem.unit.label}}
                                </div>
                            </ion-col>
                        </ion-row>
                        <ng-container *ngIf="orderItem.comment">
                            <div class="checkoutBasketItem__comment">
                                <small>{{orderItem.comment}}</small>
                            </div>
                        </ng-container>
                    </div>
                </ion-col>
            </ng-container>
        </ion-row>
        <ion-button (click)="addToBasket(order.articles)" *ngIf="isAddToBasketAvailable" expand="full">In den
            Warenkorb
        </ion-button>
        <ion-button (click)="reBuyOrder()" *ngIf="isInstantReBuyAvailable" [disabled]="!checkoutResponse" expand="full">
            Sofort neu kaufen
        </ion-button>
    </ion-grid>
</ion-content>

