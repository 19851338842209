import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ActionSheetController, AlertController, ModalController, ToastController, ViewWillEnter} from '@ionic/angular';
import {DataService} from '../../../services/data.service';
import {ArticleService} from '../../../services/article.service';
import {BasketService} from '../../../services/basket.service';
import {LoadingService} from '../../../services/util/loading.service';
import {ClientService} from '../../../services/client.service';
import {Haptics} from '@capacitor/haptics';
import {ModalCommentCRUDComponent} from '../../../modals/modal-comment-crud/modal-comment-crud.component';
import {environment} from 'src/environments/environment';
import {
    ModalMenuConfigurationComponent
} from '../../../modals/modal-menu-configuration/modal-menu-configuration.component';
import {BasketItem} from '../../../models/basket-item.model';
import {CategoryService} from '../../../services/category.service';
import {Article} from '../../../models/article.model';
import {ImageService} from '../../../services/util/image.service';
import {UnitEnum} from '../../../enums/unit.enum';
import {ProductExtrasModal} from "../../../modals/product-extras-modal/product-extras-modal";
import {ArticleAttribute} from "../../../models/article-attribute.model";

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.page.html',
    styleUrls: ['./product-detail.page.scss'],
})
export class ProductDetailPage implements ViewWillEnter {
    public article: Article = null;
    public basketArticle: BasketItem;
    public topArticles: Article[] = [];
    public attributes: ArticleAttribute[] = [];

    public unitEnum = UnitEnum;

    public amount: number;

    public comment = '';
    public availableDays: string;

    public environment = environment;
    private selectedArticleAttributes: ArticleAttribute[] = [];

    constructor(
        private router: Router,
        public dataService: DataService,
        public articleService: ArticleService,
        private route: ActivatedRoute,
        private basketService: BasketService,
        private toastController: ToastController,
        private loadingService: LoadingService,
        private alertController: AlertController,
        private modalController: ModalController,
        public actionSheetController: ActionSheetController,
        public clientService: ClientService,
        private categoryService: CategoryService,
        private modalCtrl: ModalController,
        private imageService: ImageService
    ) {
        document.addEventListener('focusout', () => {
            window.scrollTo(0, 0);
        });
    }

    public get isDecreasable(): boolean {
        if (!this.article) {
            return false;
        }
        if (this.article.minOrderAmount) {
            if (this.basketArticle) {
                if (((this.basketArticle.amount + this.amount) - this.article.clickAmount) < this.article.minOrderAmount) {
                    return false;
                }
            } else {
                if (this.amount <= this.article.minOrderAmount) {
                    return false;
                }
            }
        }

        return this.amount > this.article.clickAmount;
    }

    async ionViewWillEnter() {
        if (!await this.loadArticle(true)) {
            return;
        }

        if (this.article.categories.length && !this.categoryService.selectedCategory) {
            this.categoryService.selectedCategory = this.article.categories[0];
        }

        this.basketArticle = this.basketService.getArticleFromBasket(this.article, 0);

        if (this.article.images && this.article.images.length > 0) {
            this.article.coverImage = this.article.images[0];
        }

        this.availableDays = this.articleService.getAvailableDays(this.article);

        this.comment = '';

        this.calculateAmount();

        await this.loadTopArticles();
    }

    private async loadTopArticles(reload = false) {
        if (this.topArticles.length && !reload) {
            return;
        }
        const topArticles = await this.articleService.getTopArticles();
        this.topArticles = topArticles.filter(article => article.id !== this.article.id);
        for (const article of this.topArticles) {
            if (article.thumbnail) {
                article.coverImage = article.thumbnail;
            }
        }
    }

    private async loadArticle(reload = false) {
        if (this.article && !reload) {
            return true;
        }
        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            try {
                this.article = await this.articleService.getArticle(parseInt(id, 10));
            } catch (e) {
                const alert = await this.alertController.create({
                    message: 'Der Artikel konnte nicht geladen werden.',
                    buttons: ['OK']
                });
                await alert.present();
                await this.router.navigate(['/products']);
                return false;
            }
        } else {
            await this.router.navigate(['/products']);
            return false;
        }
        return true;
    }


    public async addToBasket() {
        if (!this.article || !this.amount) {
            return;
        }

        if (this.article.articleAttributes.length > 0) {
            const addToCart = await this.showSelectArticleAttributesModal();
            if (!addToCart) {
                return;
            }
        }

        if (this.article.menuCategories.length) {
            const actionSheet = await this.actionSheetController.create({
                header: 'Menü verfügbar',
                buttons: [
                    {
                        text: 'Als Menü konfigurieren',
                        handler: () => {
                            this.configureMenu();
                        }
                    },
                    {
                        text: 'In den Warenkorb (' + this.amount + 'x)',
                        handler: async () => {
                            await this.addArticleToBasket();
                        }
                    },
                    {
                        text: 'Abbrechen',
                        role: 'cancel'
                    }
                ]
            });
            await actionSheet.present();
            return;
        }
        await this.addArticleToBasket();

        this.calculateAmount();
    }

    private async addArticleToBasket() {
        if (!this.article || !this.amount) {
            return;
        }
        await this.loadingService.createLoading();

        this.basketService.addArticleToBasket(
            this.article,
            this.amount,
            this.comment,
            0,
            this.selectedArticleAttributes
        );

        await this.loadingService.dismissLoading();
        const toast = await this.toastController.create({
            message: 'Artikel zum Warenkorb hinzugefügt',
            duration: 1000,
            color: 'secondary',
            position: 'middle',
        });
        await Haptics.vibrate({duration: 200});
        await toast.present();
    }

    public decreaseAmount() {
        if (!this.isDecreasable) {
            return;
        }

        this.amount = this.amount - this.article.clickAmount;
    }

    private calculateAmount() {
        this.amount = this.article.clickAmount;

        if (this.article.minOrderAmount) {
            this.basketArticle = this.basketService.getArticleFromBasket(this.article, 0);
            if (this.basketArticle) {
                if (this.basketArticle.amount < this.article.minOrderAmount) {
                    this.amount = this.article.minOrderAmount - this.basketArticle.amount;
                }
            } else {
                this.amount = this.article.minOrderAmount;
            }
        }
    }

    public get isIncreasable(): boolean {
        if (!this.article) {
            return false;
        }
        return !this.article.maxOrderAmount || (this.amount < (this.article.maxOrderAmount - (this.basketArticle ? this.basketArticle.amount : 0)));
    }

    public async increaseAmount() {
        if (!this.article.maxOrderAmount || (this.amount < (this.article.maxOrderAmount - (this.basketArticle ? this.basketArticle.amount : 0)))) {
            this.amount = this.amount + this.article.clickAmount;
        } else {
            const toast = await this.toastController.create({
                message: 'Maximale Stückanzahl erreicht',
                duration: 2000,
                position: 'bottom',
                color: 'secondary'
            });
            await toast.present();
        }
    }

    public async configureMenu() {
        const modal = await this.modalController.create({
            component: ModalMenuConfigurationComponent,
            componentProps: {
                article: this.article,
                amount: this.amount
            }
        });

        await modal.present();
    }

    public async addNote() {
        const modal = await this.modalController.create({
            component: ModalCommentCRUDComponent,
            componentProps: {
                comment: this.comment
            }
        });

        await modal.present();

        modal.onWillDismiss().then(
            (response: { data }) => {
                if (response.data && response.data.comment) {
                    this.comment = response.data.comment;
                }
            }
        );
    }

    async showSelectArticleAttributesModal() {
        const modal = await this.modalCtrl.create({
            component: ProductExtrasModal,
            componentProps: {
                article: this.article,
            }
        });
        await modal.present();

        return modal.onWillDismiss()
            .then((response: { data: any, role: string }) => {
                    if (response.role !== 'confirm') {
                        return false;
                    }

                    this.selectedArticleAttributes = [];
                    if (response.data && response.data.attributes && response.data.attributes.length > 0) {
                        this.selectedArticleAttributes = response.data.attributes;
                    }
                    return true;
                }
            );
    }
}
