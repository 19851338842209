<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{ article.title }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cancel()">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-list>
        <ion-list-header lines="full">
            <ion-label>Extras hinzufügen</ion-label>
        </ion-list-header>

        <ng-container *ngFor="let attribute of article.articleAttributes">
            <ion-item lines="full">
                <ion-label class="checkbox" slot="start">
                    {{ attribute.title }} ( +{{ attribute.additionalPrice }} €)
                </ion-label>
                <ion-checkbox slot="end" justify="end" [(ngModel)]="attribute.checked"
                              (ionChange)="onCheckboxChange()">
                </ion-checkbox>
            </ion-item>
        </ng-container>
    </ion-list>
</ion-content>

<ion-footer *ngIf="selectedAttributes" class="ion-jus">
    <ion-button (click)="addToBasketFromModal()" [disabled]="!article" expand="full">
        In den Warenkorb
    </ion-button>
</ion-footer>
