<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>
      Keine Verbindung
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large" style="line-height: 1.3">Keine Verbindung</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <i class="fal fa-signal-slash" style="color: var(--ion-color-primary); font-size: 100px; margin: 100px 0" aria-hidden="true"></i>
        <h1>Keine Verbindung</h1>
        <p>Die Verbindung zum Server konnte nicht hergestellt werden.</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button expand="full" color="primary" (click)="restartApp()">Erneut versuchen</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
