import {Serializable} from './serializable.abstract';
import {Image} from './image.model';
import {BaseBasketItemDTO} from './base-basket-item.model';
import {UnitDefinition} from './unit-definition';
import {SellUnitDefinition} from './sell-unit-definition';

export class CheckoutBasketItemDTO extends BaseBasketItemDTO {
    images: Image[] = [];
    price = 0;
    specialPrice: number;
    specialPriceValidFrom: Date;
    specialPriceValidUntil: Date;
    status = '';
    title = '';
    unit: UnitDefinition;
    sellUnit: SellUnitDefinition;
    comment = '';
    clickAmount = 0;
    maxOrderAmount = 0;
    weekDay: number;
    date: string;
    minItems: number;

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexType('unit', input, UnitDefinition);
        this.deserializeComplexType('sellUnit', input, SellUnitDefinition);
        this.deserializeComplexArray('images', input, Image);
        this.deserializeDate('specialPriceValidFrom', input);
        this.deserializeDate('specialPriceValidUntil', input);
        return this;
    }
}

export class CheckoutBasketItem extends CheckoutBasketItemDTO {
    public get isDecreasable(): boolean {
        if (this.minItems) {
            return (this.amount - this.clickAmount) >= this.minItems;
        }
        return this.amount - this.clickAmount !== 0;
    }

    public get isIncreasable(): boolean {
        return !this.maxOrderAmount || this.amount < this.maxOrderAmount;
    }

    public get priceWithoutModifiers(): number {
        let price = this.price;

        if (this.articleAttributes) {
            this.articleAttributes.forEach((attribute) => {
                price -= attribute.additionalPrice;
            });
        }

        if (this.additionalArticles) {
            this.additionalArticles.forEach((additionalArticle) => {
                price -= additionalArticle.additionalPrice;
            });
        }

        return price;
    }

    public get specialPriceWithoutModifiers(): number {
        let price = this.specialPrice;

        if (this.articleAttributes) {
            this.articleAttributes.forEach((attribute) => {
                price -= attribute.additionalPrice;
            });
        }

        if (this.additionalArticles) {
            this.additionalArticles.forEach((additionalArticle) => {
                price -= additionalArticle.additionalPrice;
            });
        }

        return price;
    }
}


