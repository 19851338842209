import {Component} from '@angular/core';
import {BasketService} from '../../../services/basket.service';
import {ClientService} from '../../../services/client.service';
import {environment} from 'src/environments/environment';
import {ImageService} from '../../../services/util/image.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    get headerLoading(): boolean {
        return this.logoLoading;
    }

    get logoLoading(): boolean {
        return this._logoLoading;
    }

    set logoLoading(value: boolean) {
        this._logoLoading = value;
    }

    public now = new Date();
    public environment = environment;

    private _logoLoading = true;

    constructor(
        public basketService: BasketService,
        public clientService: ClientService,
        public imageService: ImageService
    ) {
    }
}
