import {Serializable} from './serializable.abstract';
import {PaymentType} from '../enums/payment-type.enum';
import {PaymentStatus} from '../enums/payment-status.enum';
import {CheckoutBasketItem} from './checkout-basket-item.model';

export class OrderDTO extends Serializable {
    companyName: string;
    firstName: string;
    lastName: string;
    street: string;
    zip: string;
    location: string;
    email: string;
    phone: string;
    notificationToken: string;
    uniqueDeviceId: string;
    desiredPickupTime: Date;
    status: number;
    // stripeToken: string;
    clientSecret: string;
    articles: CheckoutBasketItem[];
    branch: number;
    branchTitle: string;
    paymentType: PaymentType;
    paymentStatus: PaymentStatus;
    delivery: boolean;
    deliveryInstructions: string;

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('articles', input, CheckoutBasketItem);
        return this;
    }

    serialize(): any {
        const ret = {
            companyName: this.companyName,
            firstName: this.firstName,
            lastName: this.lastName,
            street: this.street,
            zip: this.zip,
            location: this.location,
            email: this.email,
            phone: this.phone,
            notificationToken: this.notificationToken,
            uniqueDeviceId: this.uniqueDeviceId,
            desiredPickupTime: new Date(this.desiredPickupTime),
            status: this.status,
            branch: this.branch,
            articles: [],
            paymentType: this.paymentType,
            delivery: this.delivery,
            deliveryInstructions: this.deliveryInstructions
        };

        this.articles.forEach(basketItem => {
            ret.articles.push(
                {
                    id: basketItem.id,
                    count: basketItem.amount,
                    additionalArticles: basketItem.additionalArticles,
                    articleAttributes: basketItem.articleAttributes,
                    comment: basketItem.comment,
                    type: basketItem.type,
                    date: basketItem.date,
                    title: basketItem.title,
                    unit: basketItem.unit,
                    sellUnit: basketItem.sellUnit
                }
            );
        });

        return ret;
    }
}

export class Order extends OrderDTO {
    pickupMin: Date;
    pickupMax: Date;
    availableDays: number[];
    disabledDays: number[];
    leadTimeMax: number;
    paymentPossible: boolean;
    deliveryPossible: boolean;
    deliveryAvailableDays: number[];
    deliveryDisabledDays: number[];
}
