import {Injectable} from '@angular/core';
import {User} from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    get user(): User {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            return new User().deserialize(JSON.parse(storedUser)) as User;
        }
        return this._user;
    }

    set user(value: User) {
        if (value) {
            localStorage.setItem('user', JSON.stringify(value));
        } else {
            localStorage.removeItem('user');
        }
        this._user = value;
    }

    private _user: User;
}
