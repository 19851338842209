import {Serializable} from './serializable.abstract';
import {LunchDay} from './lunch-day.model';

export class LunchWeekDTO extends Serializable {
    calendarWeeks: string;
    currentWeek: number;
    lunchDays: LunchDay[];

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('lunchDays', input, LunchDay);
        return this;
    }
}
export class LunchWeek extends LunchWeekDTO {
}
