<ion-header>
    <ion-toolbar>
        <ion-title>
            Menü konfigurieren
        </ion-title>
        <ion-buttons slot="primary">
            <ion-button (click)="modalCtrl.dismiss()">
                Abbrechen
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col size="12">
                <mat-horizontal-stepper #menuConfigurationStepper (selectionChange)="selectionChanged($event)"
                                        [linear]="true" labelPosition="bottom">
                    <ng-container *ngFor="let menuCategory of article.menuCategories; let index = index">
                        <mat-step [completed]="article.menuCategories[index]?.checkedMenuArticles.length > 0">
                            <ng-template matStepLabel>{{menuCategory.title}}</ng-template>
                            <ion-row class="as-tile-pair">
                                <ng-container *ngFor="let menuArticle of menuCategory.menuArticles">
                                    <ion-col class="as-tile" size="6">
                                        <ng-container *ngIf="menuArticle.additionalPrice > 0">
                                            <app-article-card-item (click)="addToMenu(menuCategory, menuArticle)"
                                                                   [article]="menuArticle.article"
                                                                   [class.checked]="menuArticle.checked"
                                                                   [showBasketAmount]="false"
                                                                   [title]="menuArticle.title"
                                                                   overridePriceSection="<div class='ion-text-right text-color-primary'>{{menuArticle.additionalPrice | currency: 'EUR'}}</div>"></app-article-card-item>
                                        </ng-container>
                                        <ng-container *ngIf="menuArticle.additionalPrice === 0">
                                            <ng-container *ngIf="menuArticle.id === 0">
                                                <app-article-card-item (click)="addToMenu(menuCategory, menuArticle)"
                                                                       [article]="menuArticle.article"
                                                                       [class.checked]="menuArticle.checked"
                                                                       [showBasketAmount]="false"
                                                                       [title]="menuArticle.title"
                                                                       overridePriceSection="<div></div>"></app-article-card-item>
                                            </ng-container>
                                            <ng-container *ngIf="menuArticle.id !== 0">
                                                <app-article-card-item (click)="addToMenu(menuCategory, menuArticle)"
                                                                       [article]="menuArticle.article"
                                                                       [class.checked]="menuArticle.checked"
                                                                       [showBasketAmount]="false"
                                                                       [title]="menuArticle.title"
                                                                       overridePriceSection="<div>inkludiert</div>"></app-article-card-item>
                                            </ng-container>
                                        </ng-container>
                                    </ion-col>
                                </ng-container>
                            </ion-row>
                        </mat-step>
                    </ng-container>
                    <mat-step [completed]="false">
                        <ng-template matStepLabel>Menü bestätigen</ng-template>
                        <ion-row>
                            <ion-col size="12">
                                <app-article-card-item-horizontal [article]="article"
                                                                  [showBasketAmount]="false"></app-article-card-item-horizontal>
                            </ion-col>
                            <ng-container *ngFor="let menuCategory of article.menuCategories">
                                <ng-container *ngFor="let menuArticle of menuCategory.checkedMenuArticles">
                                    <ion-col size="6">
                                        <ng-container *ngIf="menuArticle.additionalPrice > 0">
                                            <app-article-card-item [article]="menuArticle.article"
                                                                   [showBasketAmount]="false"
                                                                   [title]="menuArticle.title"
                                                                   overridePriceSection="<div class='ion-text-right text-color-primary'>{{menuArticle.additionalPrice | currency: 'EUR'}}</div>"></app-article-card-item>
                                        </ng-container>
                                        <ng-container *ngIf="menuArticle.additionalPrice === 0">
                                            <ng-container *ngIf="menuArticle.id === 0">
                                                <app-article-card-item [article]="menuArticle.article"
                                                                       [showBasketAmount]="false"
                                                                       [title]="menuArticle.title"
                                                                       overridePriceSection="<div></div>"></app-article-card-item>
                                            </ng-container>
                                            <ng-container *ngIf="menuArticle.id !== 0">
                                                <app-article-card-item [article]="menuArticle.article"
                                                                       [showBasketAmount]="false"
                                                                       [title]="menuArticle.title"
                                                                       overridePriceSection="<div>inkludiert</div>"></app-article-card-item>
                                            </ng-container>
                                        </ng-container>
                                    </ion-col>
                                </ng-container>
                            </ng-container>
                        </ion-row>
                        <ion-row class="ion-align-items-center ion-margin-top">
                            <ion-col class="ion-text-center" size="4">
                                <ion-button (click)="decreaseAmount()" [disabled]="amount <= article.clickAmount"
                                            class="ion-no-margin"
                                            expand="full">
                                    <ion-icon name="remove-outline"></ion-icon>
                                </ion-button>
                            </ion-col>
                            <ion-col class="ion-text-center" size="4">
                                <span>{{amount}} {{ article?.sellUnit.label }}</span>
                            </ion-col>
                            <ion-col class="ion-text-center" size="4">
                                <ion-button (click)="increaseAmount()" [disabled]="amount >= article.maxOrderAmount"
                                            class="ion-no-margin" expand="full">
                                    <ion-icon name="add-circle-outline"></ion-icon>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-button (click)="addNoteToMenu()" class="ion-no-margin" color="secondary"
                                            expand="full">
                                    <ion-icon name="create-outline"></ion-icon>
                                    Anmerkung
                                </ion-button>
                            </ion-col>
                        </ion-row>
                        <ion-row *ngIf="comment">
                            <ion-col size="12">
                                <small>{{comment}}</small>
                            </ion-col>
                        </ion-row>
                    </mat-step>
                </mat-horizontal-stepper>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col size="6">
                    <ng-container *ngIf="menuConfigurationStepper.selectedIndex === 0">
                        <ion-button (click)="modalCtrl.dismiss()" expand="full">Abbrechen</ion-button>
                    </ng-container>
                    <ng-container *ngIf="menuConfigurationStepper.selectedIndex > 0">
                        <ion-button (click)="menuConfigurationStepper.previous()" expand="full">Zurück</ion-button>
                    </ng-container>
                </ion-col>
                <ion-col size="6">
                    <ng-container *ngIf="!isLastPage">
                        <ion-button (click)="menuConfigurationStepper.next()"
                                    [disabled]="!article.menuCategories[menuConfigurationStepper.selectedIndex]?.checkedMenuArticles.length"
                                    color="primary"
                                    expand="full">Weiter
                        </ion-button>
                    </ng-container>
                    <ng-container *ngIf="isLastPage">
                        <ion-button (click)="addMenuToBasket()" color="primary" expand="full">In den Warenkorb
                        </ion-button>
                    </ng-container>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
