import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {LunchWeek, LunchWeekDTO} from '../models/lunch-week.model';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {MenuArticle} from '../models/menu-article.model';
import {LunchArticle} from 'src/app/models/lunch-article.model';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(
        private dataService: DataService
    ) {
    }

    public getArticle(id: number): Promise<LunchArticle> {
        return this.dataService.get('/lunch-article/' + id, environment.clientId)
            .pipe(
                map(response => {
                    return new LunchArticle().deserialize(response) as LunchArticle;
                })
            )
            .toPromise();
    }

    public getCurrentWeeks(): Promise<LunchWeek[]> {
        return this.dataService.get('/menu/currentWeek', environment.clientId)
            .pipe(
                map((response: any[]) => {
                    const lunchWeeks = [];

                    response.forEach(item => {
                        const lunchWeek = new LunchWeekDTO().deserialize(item) as LunchWeek;

                        lunchWeeks.push(lunchWeek);
                    });

                    return lunchWeeks;
                })
            ).toPromise();
    }

    public getArticlesOfMenuCategory(menuCategoryId: number): Promise<MenuArticle[]> {
        return this.dataService.get('/menu-category/' + menuCategoryId + '/articles', environment.clientId)
            .pipe(
                map((response: any[]) => {
                    const articles = [];

                    response.forEach(item => {
                        articles.push(new MenuArticle().deserialize(item) as MenuArticle);
                    });

                    return articles;
                })
            ).toPromise();
    }
}
