import {Attribute, Injectable} from '@angular/core';
import {Article} from '../models/article.model';
import {Subject} from 'rxjs';
import {Basket} from '../models/basket.model';
import {BasketItem} from '../models/basket-item.model';
import {OrderItemType} from '../enums/order-item-type.enum';
import {LunchDay} from '../models/lunch-day.model';
import {MenuArticle} from 'src/app/models/menu-article.model';
import {A} from "@angular/cdk/keycodes";
import {ArticleAttribute} from "../models/article-attribute.model";

@Injectable({
    providedIn: 'root'
})
export class BasketService {
    public basket: Basket = null;
    public reBuyBasket: Basket = null;
    public onBasketChange = new Subject<void | Article>();
    public onBasketItemDeleted = new Subject<void | Article>();

    constructor() {
        let storedBasket = localStorage.getItem('basket');
        if (storedBasket) {
            storedBasket = JSON.parse(storedBasket);
            this.basket = new Basket().deserialize(storedBasket) as Basket;
        } else {
            this.basket = new Basket();
        }
    }

    public getArticleFromBasket(article: Article, type: OrderItemType) {
        return this.basket.articles.find(basketItem => basketItem.hash === article.id + '_' + type);
    }

    public addArticleToBasket(
        article: Article,
        amount: number,
        comment: string,
        type: OrderItemType,
        selectedArticleAttributes?: ArticleAttribute[],
    ) {
        const articleToAdd = new BasketItem();
        articleToAdd.id = article.id;
        articleToAdd.amount = amount;
        articleToAdd.comment = comment;
        articleToAdd.type = type;

        if (selectedArticleAttributes) {
            articleToAdd.articleAttributes = selectedArticleAttributes;
        }

        const articleInBasket = this.basket.articles.find(basketItem => basketItem.hash === articleToAdd.hash);

        if (articleInBasket) {
            const newAmount = articleInBasket.amount + amount;

            if (newAmount > article.maxOrderAmount) {
                return;
            }

            articleInBasket.amount = newAmount;
            articleInBasket.comment = comment;
            articleInBasket.type = type;
            articleInBasket.title = article.title;
            articleInBasket.unit = article.unit;
            articleInBasket.sellUnit = article.sellUnit;

            if (selectedArticleAttributes) {
                articleInBasket.articleAttributes = selectedArticleAttributes;
            }

            this.saveBasket();
            return;
        }

        this.basket.items.push(articleToAdd);

        this.saveBasket();
    }

    public addMenuToBasket(
        id: number,
        article: Article,
        additionalArticles: MenuArticle[],
        amount: number,
        comment: string,
        type: OrderItemType,
        lunchDay: LunchDay = null
    ) {
        const menuToAdd = new BasketItem();

        menuToAdd.id = id;
        menuToAdd.additionalArticles = additionalArticles;
        menuToAdd.amount = amount;
        menuToAdd.comment = comment;
        menuToAdd.type = type;

        if (type === OrderItemType.LUNCH_ARTICLE) {
            if (!lunchDay) {
                return;
            }
            menuToAdd.lunchDay = {date: lunchDay.date, weekDay: lunchDay.weekDay};
        }

        const menuInBasket = this.basket.menus.find(basketItem => basketItem.hash === menuToAdd.hash);

        if (menuInBasket) {
            const newAmount = menuInBasket.amount + amount;

            if (newAmount > article.maxOrderAmount) {
                return;
            }

            menuInBasket.amount = newAmount;
            menuInBasket.comment = comment;

            this.saveBasket();
            return;
        }

        this.basket.items.push(menuToAdd);

        this.saveBasket();
    }


    public decreaseAmount(hash: string, amount: number) {
        const foundBasketItem = this.basket.items.find(basketItem => basketItem.hash === hash);

        if (foundBasketItem) {
            foundBasketItem.amount -= amount;
            this.saveBasket();
        }
    }

    public increaseAmount(hash: string, amount: number) {
        const foundBasketItem = this.basket.items.find(basketItem => basketItem.hash === hash);

        if (foundBasketItem) {
            foundBasketItem.amount += amount;
            this.saveBasket();
        }
    }

    public removeArticle(hash: string) {
        this.basket.items = this.basket.items.filter(item => item.hash !== hash);
        this.saveBasket();
        this.onBasketChange.next();
        this.onBasketItemDeleted.next();
    }

    public deleteBasket() {
        this.basket = new Basket();
        this.saveBasket();
    }

    public saveBasket() {
        localStorage.setItem('basket', JSON.stringify(this.basket));
    }

    public saveComment(hash: string, comment: string, override = false) {
        const article = this.basket.items.find(basketArticle => basketArticle.hash === hash);
        if (article) {
            if (override) {
                article.overrideComment(comment);
            } else {
                article.comment = comment;
            }
        }
        this.saveBasket();
    }

    public findArticleWithIdInBasket(id: number) {
        if (this.basket) {
            return this.basket.articles.find(article => article.id === id);
        }
        return null;
    }

    public saveAttributes(hash: string, attributesToAdd: ArticleAttribute[]) {
        const article = this.basket.items.find(basketArticle => basketArticle.hash === hash);
        if (article) {
            article.articleAttributes = attributesToAdd
        }
        this.saveBasket();
    }
}
