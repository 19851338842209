import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import jsSHA from 'jssha';
import {DataService} from '../data.service';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    private readonly imageSecret = 'e861fdf2cd83f9c38030e7dcee3081f7c5d1e1a6080f920f3bccb079b56b23d674ab902c13905361f92ce2ae14f3489e';

    constructor(
        private dataService: DataService
    ) {
    }

    public getImage(id, width = '', height = '') {
        const params = {
            client: environment.clientId.toString(10),
            id
        };
        if (width) {
            Object.assign(params, {width});
        }
        if (height) {
            Object.assign(params, {height});
        }
        const hashString = '/api/image?' + new URLSearchParams(params).toString();
        Object.assign(params, {hash: this.getImageHash(hashString)});
        return this.dataService.apiUrl + '/image?' + new URLSearchParams(params).toString();
    }

    private getImageHash(hashString: string) {
        const shaObj = new jsSHA('SHA-256', 'TEXT', {
            hmacKey: {value: this.imageSecret, format: 'TEXT'},
        });
        shaObj.update(hashString);
        return shaObj.getHash('HEX');
    }
}
