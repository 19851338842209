import {Serializable} from './serializable.abstract';
import {Image} from './image.model';

export class CategoryDTO extends Serializable {
    title: string;
    description: string;
    parent: number;
    image: Image[];

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('image', input, Image);
        return this;
    }
}

export class Category extends CategoryDTO {

    isLoading = true;
}
