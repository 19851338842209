import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Device} from '@capacitor/device';
import {LocalStorageService, UUID_STORAGE_KEY} from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    get deviceId(): string {
        return this._deviceId;
    }

    set deviceId(value: string) {
        this._deviceId = value;
    }

    private _deviceId: string;

    constructor(
        private platform: Platform,
        private localStorageService: LocalStorageService
    ) {
    }

    public async initializeUniqueDeviceId() {
        const capacitorDeviceId = await Device.getId();
        if (this.platform.is('cordova') && this.platform.is('mobile') && !this.platform.is('mobileweb')) {
            if (capacitorDeviceId.identifier) {
                this.deviceId = capacitorDeviceId.identifier;
                return;
            }
        } else {
            const storedDeviceId = this.localStorageService.retrieve(UUID_STORAGE_KEY);
            if (storedDeviceId) {
                this.deviceId = storedDeviceId;
                return;
            }

            this.deviceId = capacitorDeviceId.identifier;

            if (this.deviceId) {
                this.localStorageService.persist(UUID_STORAGE_KEY, this.deviceId);
            }
        }
    }
}
