import {Component, Input, OnInit} from '@angular/core';
import {Category} from '../../models/category.model';

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss'],
})
export class CategorySelectComponent implements OnInit {
  @Input() categories: Category[];

  constructor(

  ) { }

  public async ngOnInit() {

  }



}
