import {Component, Input, OnInit} from '@angular/core';
import {LunchArticle} from '../../models/lunch-article.model';
import {DataService} from '../../services/data.service';
import {ClientService} from '../../services/client.service';
import {ImageService} from '../../services/util/image.service';
import {environment} from 'src/environments/environment';
import {Image} from '../../models/image.model';

@Component({
    selector: 'app-menu-card-item',
    templateUrl: './menu-card-item.component.html',
    styleUrls: ['./menu-card-item.component.scss'],
})
export class MenuCardItemComponent implements OnInit {
    @Input() lunchArticle: LunchArticle;
    @Input() cols = 0;

    public articleImage: Image;

    public environment = environment;

    constructor(
        private imageService: ImageService,
        public dataService: DataService,
        public clientService: ClientService
    ) {
    }

    async ngOnInit() {
        if (!this.lunchArticle) {
            return;
        }

        if (this.lunchArticle.article.images && this.lunchArticle.article.images.length > 0) {
            this.articleImage = this.lunchArticle.article.images[0];
        }
    }
}

