import {Serializable} from './serializable.abstract';

export class AllergenDTO extends Serializable {
    id: number;
    title: string;

    public deserialize(input: any): Serializable {
        super.deserialize(input);
        return this;
    }
}

export class Allergen extends AllergenDTO {
}
