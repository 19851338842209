import {Component, OnInit} from '@angular/core';
import {ClientService} from '../../services/client.service';

@Component({
  selector: 'app-modal-availability',
  templateUrl: './modal-availability.component.html',
  styleUrls: ['./modal-availability.component.scss'],
})
export class ModalAvailabilityComponent implements OnInit {

  constructor(
      public clientService: ClientService
  ) { }

  ngOnInit() {}

}
