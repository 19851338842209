<ion-card class="ion-no-padding" [class.desktop]="environment.desktop">
    <ion-card-content class="ion-no-padding">
        <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding">
                    <ion-col size="6" class="ion-no-padding">
                        <div class="article-image-wrapper"
                             *ngIf="articleImage || this.clientService.client?.articlePlaceholder"
                             [style.background-image]="articleImage ? 'url(' + articleImage.path + ')' : 'url(' + clientService.client?.articlePlaceholder.path + ')'"></div>
                    </ion-col>
                    <ion-col size="6" style="display: flex; align-items: center">
                        <div class="ion-padding">
                            <span class="card-title-menu-bold">{{(lunchArticle.title.length > 25) ? (lunchArticle.title | slice:0:25) + '...' : (lunchArticle.title)}}</span><br>
                            <span class="card-subtitle-menu">{{(lunchArticle.subheader.length > 25) ? (lunchArticle.subheader | slice:0:25) + '...' : (lunchArticle.subheader)}}</span><br>
                            <span class="card-title-menu">ab {{lunchArticle.price | currency: 'EUR'}}</span>
                        </div>
                    </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>
