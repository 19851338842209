<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Filiale auswählen</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="branchForm">
    <ion-grid>
      <ion-row>
        <ion-col *ngIf="branches.length" size="12">
          <ion-list>
            <ion-radio-group formControlName="branch">
              <ng-container *ngFor="let branch of branches">
                <ion-item>
                  <ion-label>
                    <h2>{{branch.title}}</h2>
                    <ng-container *ngIf="branch.street && branch.zip && branch.city">
                      <p>{{branch.street}}, {{branch.zip}} {{branch.city}}</p>
                    </ng-container>
                  </ion-label>
                  <ion-radio [value]="branch" color="primary"
                             slot="end"></ion-radio>
                </ion-item>
              </ng-container>
            </ion-radio-group>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar color="primary">
    <ion-button (click)="saveSelectedBranch()" [disabled]="branchForm.invalid" color="transparent" expand="full"
                mode="ios">Auswahl bestätigen
    </ion-button>
  </ion-toolbar>
</ion-footer>
