import {Serializable} from './serializable.abstract';
import {MenuArticle} from './menu-article.model';

export class MenuCategoryDTO extends Serializable {
    title = '';
    menuArticles: MenuArticle[] = [];

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('menuArticles', input, MenuArticle);
        return this;
    }
}

export class MenuCategory extends MenuCategoryDTO {

    get checkedMenuArticles(): MenuArticle[] {
        return this.menuArticles.filter(menuArticle => menuArticle.checked);
    }

    public uncheckMenuArticles(): void {
        this.menuArticles.forEach(menuArticle => menuArticle.checked = false);
    }
}
