import {Injectable} from '@angular/core';
import {DataService} from '../data.service';
import {VersionCheckResponse} from '../../models/version-check-response';

@Injectable({
    providedIn: 'root'
})
export class VersionService {
    constructor(
        private dataService: DataService
    ) {
    }

    public async check(currentVersion: string): Promise<VersionCheckResponse> {
        return await this.dataService.get<VersionCheckResponse>('/version/check', {currentVersion}).toPromise();
    }
}
