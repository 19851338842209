<ion-header>
    <ion-toolbar color="primary">
        <ion-title>
            Anmerkung
        </ion-title>
        <ion-buttons slot="primary">
            <ion-button [disabled]="!comment" (click)="delete()">
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-textarea placeholder="Anmerkung hier einfügen..." rows="10" [(ngModel)]="comment"></ion-textarea>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="6">
                <ion-button expand="full" color="secondary" (click)="dismiss()" [disabled]="hiding">Abbrechen</ion-button>
            </ion-col>
            <ion-col size="6">
                <ion-button expand="full" color="primary" (click)="save()" [disabled]="hiding">Speichern</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
