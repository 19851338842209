import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
import {BasketService} from '../../../services/basket.service';
import {ModalCommentCRUDComponent} from '../../../modals/modal-comment-crud/modal-comment-crud.component';
import {CheckoutBasketItem} from '../../../models/checkout-basket-item.model';
import {ProductExtrasModal} from "../../../modals/product-extras-modal/product-extras-modal";
import {ArticleService} from "../../../services/article.service";
import {LoadingService} from "../../../services/util/loading.service";

@Component({
    selector: 'app-basket-item',
    templateUrl: './basket-item.component.html',
    styleUrls: ['./basket-item.component.scss'],
})
export class BasketItemComponent {
    @Input() checkoutBasketItem: CheckoutBasketItem;
    @Output() editArticleAttributes: EventEmitter<CheckoutBasketItem> = new EventEmitter<CheckoutBasketItem>();

    constructor(
        public basketService: BasketService,
        private toastController: ToastController,
        private alertController: AlertController,
        private modalController: ModalController,
        private articleService: ArticleService,
        private loadingService: LoadingService
    ) {
    }

    public async decreaseAmount(checkoutBasketItem: CheckoutBasketItem) {
        this.basketService.decreaseAmount(checkoutBasketItem.hash, checkoutBasketItem.clickAmount);
        checkoutBasketItem.amount -= checkoutBasketItem.clickAmount;
    }

    public async increaseAmount(checkoutBasketItem: CheckoutBasketItem) {
        if (checkoutBasketItem.maxOrderAmount && checkoutBasketItem.amount >= checkoutBasketItem.maxOrderAmount) {
            const toast = await this.toastController.create({
                message: 'Maximale Stückanzahl erreicht',
                duration: 2000,
                position: 'top',
                color: 'secondary'
            });
            await toast.present();
            return;
        }
        this.basketService.increaseAmount(checkoutBasketItem.hash, checkoutBasketItem.clickAmount);
        checkoutBasketItem.amount += checkoutBasketItem.clickAmount;
    }

    public async removeArticleFromBasket(checkoutBasketItem: CheckoutBasketItem) {
        const alert = await this.alertController.create({
            header: checkoutBasketItem.title,
            message: 'Soll dieser Artikel aus Ihrem Warenkorb entfernt werden?',
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel',
                    handler: () => {

                    }
                }, {
                    text: 'Artikel entfernen',
                    handler: async () => {
                        this.basketService.removeArticle(checkoutBasketItem.hash);
                    }
                }
            ]
        });

        await alert.present();
    }

    public async addNote(checkoutBasketItem: CheckoutBasketItem) {
        const modal = await this.modalController.create({
            component: ModalCommentCRUDComponent,
            componentProps: {
                comment: checkoutBasketItem.comment
            }
        });

        await modal.present();

        modal.onWillDismiss().then(
            (response: { data }) => {
                if (response.data) {
                    checkoutBasketItem.comment = response.data.comment;
                    this.basketService.saveComment(checkoutBasketItem.hash, checkoutBasketItem.comment, true);
                }
            }
        );
    }

    async editExtras(checkoutBasketItem: CheckoutBasketItem) {
        this.loadingService.createLoading();
        const article = await this.articleService.getArticle(checkoutBasketItem.id);
        this.loadingService.dismissLoading();

        const modal = await this.modalController.create({
            component: ProductExtrasModal,
            componentProps: {
                article: article,
                selectedAttributes: checkoutBasketItem.articleAttributes
            }
        });
        await modal.present();

        modal.onWillDismiss().then(
            (response: { data }) => {
                if (response.data) {
                    const oldHash = checkoutBasketItem.hash;
                    checkoutBasketItem.articleAttributes = response.data.attributes;
                    this.basketService.saveAttributes(oldHash, checkoutBasketItem.articleAttributes);
                    this.editArticleAttributes.emit(checkoutBasketItem);
                }
            }
        );

    }
}
