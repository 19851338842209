import {Serializable} from './serializable.abstract';

export class ArticleAttributeDTO extends Serializable {
    title: string;
    additionalPrice: number;

    deserialize(input: any): Serializable {
        return super.deserialize(input);
    }
}

export class ArticleAttribute extends ArticleAttributeDTO {
    checked: boolean;
}
