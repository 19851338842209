import {Serializable} from './serializable.abstract';
import {PaymentType} from '../enums/payment-type.enum';
import {PaymentStatus} from '../enums/payment-status.enum';
import {OrderArticle} from './order-article.model';

export class OrderResponseDTO extends Serializable {
    companyName: string;
    firstName: string;
    lastName: string;
    street: string;
    zip: string;
    location: string;
    email: string;
    phone: string;
    notificationToken: string;
    uniqueDeviceId: string;
    desiredPickupTime: Date;
    status: number;
    // stripeToken: string;
    clientSecret: string;
    articles: OrderArticle[];
    branch: number;
    branchTitle: string;
    sellerNotice: string;
    paymentType: PaymentType;
    paymentStatus: PaymentStatus;
    tstamp: Date;
    delivery: boolean;
    deliveryInstructions: string;
    grossSum: number;

    deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('articles', input, OrderArticle);
        this.deserializeDate('desiredPickupTime', input);
        this.deserializeDate('tstamp', input);

        return this;
    }
}

export class OrderResponse extends OrderResponseDTO {
    pickupMin: Date;
    pickupMax: Date;
    availableDays: number[];
    disabledDays: number[];
    leadTimeMax: number;
    paymentPossible: boolean;
}
