import {Serializable} from './serializable.abstract';

export class ImageDTO extends Serializable {
    id: number;
    path: string;

    deserialize(input: any): Serializable {
        return super.deserialize(input);
    }
}

export class Image extends ImageDTO {
    coverBanner: string;
}
