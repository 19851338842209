<ion-card class="md hydrated" mode="ios">
    <ion-grid class="ion-no-padding" [class.desktop]="environment.desktop">
        <ion-row>
            <ion-col size="6">
                <div class="article-image-wrapper"
                     *ngIf="articleImage || this.clientService.client?.articlePlaceholder"
                     [style.background-image]="articleImage ? 'url(' + articleImage.path + ')' : 'url(' + clientService.client?.articlePlaceholder.path + ')'"></div>
            </ion-col>
            <ion-col size="6">
                <div style="display: flex;align-items: center; justify-content: center; height: 100%">
                    <ion-label>
                        <span class="card-title-menu-bold">
                            <ng-container *ngIf="title">
                                {{(title.length > dataService.maxCharacters) ? (title | slice:0:dataService.maxCharacters) + '...' : (title)}}
                            </ng-container>
                            <ng-container *ngIf="!title">
                                {{(article.title.length > dataService.maxCharacters) ? (article.title | slice:0:dataService.maxCharacters) + '...' : (article.title)}}
                            </ng-container>
                        </span><br>
                        <span class="card-title-menu"
                              *ngIf="!overridePriceSection">{{ article.price | currency: 'EUR' }}
                            pro {{ article.unit.label }}</span><br>
                        <span class="card-title-menu" *ngIf="overridePriceSection"
                              [innerHTML]="overridePriceSection"></span>
                    </ion-label>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-card>
