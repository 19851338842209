<ion-header>
    <ion-toolbar color="primary">
        <ion-buttons slot="end">
            <ion-button (click)="modalCtrl.dismiss()" class="header-text">
                Abbrechen
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col size="12" class="ion-padding">
                <ion-label class="header-text">
                    <strong>{{lunchArticle.title}}</strong> konfigurieren<ng-container *ngIf="lunchArticle.subheader"><br/>{{lunchArticle.subheader}}</ng-container>
                </ion-label>
            </ion-col>
            <ion-col size="12">
                <mat-horizontal-stepper labelPosition="bottom" linear="false"
                                        (selectionChange)="selectionChanged($event)" #menuConfigurationStepper>
                    <ng-container *ngFor="let lunchCategory of lunchArticle.lunchCategories; let index = index">
                        <mat-step label="{{lunchCategory.title}}"
                                  [completed]="lunchArticle.lunchCategories[index]?.checkedLunchArticles.length > 0">
                            <ion-row>
                                <ng-container *ngFor="let menuArticle of lunchCategory.menuArticles">
                                    <ion-col size="6">
                                        <div *ngIf="menuArticle.additionalPrice === 0; then noMorePrice else morePrice"></div>
                                        <ng-template #morePrice>
                                            <app-article-card-item [title]="menuArticle.title" [article]="menuArticle.article"
                                                                   [showBasketAmount]="false"
                                                                   (click)="addToMenu(lunchCategory ,menuArticle)"
                                                                   [class.checked]="menuArticle.checked"
                                                                   overridePriceSection="<div>+ {{menuArticle.additionalPrice | currency: 'EUR'}}</div>">
                                            </app-article-card-item>
                                        </ng-template>
                                        <ng-template #noMorePrice>
                                            <div *ngIf="menuArticle.id === 0; then without else included"></div>
                                            <ng-template #included>
                                                <app-article-card-item [title]="menuArticle.title" [article]="menuArticle.article"
                                                                                          [showBasketAmount]="false"
                                                                                          (click)="addToMenu(lunchCategory ,menuArticle)"
                                                                                          [class.checked]="menuArticle.checked"
                                                                                          overridePriceSection="<div>inkludiert</div>"></app-article-card-item>
                                            </ng-template>
                                            <ng-template #without>
                                                <app-article-card-item [title]="menuArticle.title" [article]="menuArticle.article"
                                                                       [showBasketAmount]="false"
                                                                       (click)="addToMenu(lunchCategory ,menuArticle)"
                                                                       [class.checked]="menuArticle.checked"
                                                                       overridePriceSection="<div></div>"></app-article-card-item>
                                            </ng-template>

                                        </ng-template>
                                    </ion-col>
                                </ng-container>
                            </ion-row>
                        </mat-step>
                    </ng-container>
                    <mat-step label="Menü bestätigen" [completed]="false">
                        <ion-grid>
                            <ion-row>
                                <ion-col size="12">
                                    <app-menu-card-item [lunchArticle]="lunchArticle"
                                                        class="ion-no-padding"></app-menu-card-item>
                                </ion-col>
                                <ng-container *ngFor="let orderArticle of lunchArticle.lunchCategories">
                                    <ng-container
                                            *ngFor="let checkedArticle of orderArticle.checkedLunchArticlesWithoutFake">
                                        <ion-col size="6">
                                            <div *ngIf="checkedArticle.additionalPrice === 0; then noMorePrice else morePrice"></div>
                                            <ng-template #morePrice>
                                                <app-article-card-item [title]="checkedArticle.title" [article]="checkedArticle.article"
                                                                       [showBasketAmount]="false"
                                                                       overridePriceSection="<div>+ {{checkedArticle.additionalPrice | currency: 'EUR'}}</div>">
                                                </app-article-card-item>
                                            </ng-template>
                                            <ng-template #noMorePrice>
                                                <app-article-card-item [title]="checkedArticle.title" [article]="checkedArticle.article"
                                                                       [showBasketAmount]="false"
                                                                       overridePriceSection="<div>inkludiert</div>"></app-article-card-item>
                                            </ng-template>
                                        </ion-col>
                                    </ng-container>
                                </ng-container>
                                <ion-col size="12" class="ion-text-center ion-padding">
                                    <ion-label class="priceText">Gesamtpreis
                                        <strong>{{fullPrice | currency: 'EUR'}}</strong></ion-label>
                                </ion-col>
                            </ion-row>
                            <ion-row class="ion-align-items-center ion-margin-top">
                                <ion-col class="ion-text-center" size="4">
                                    <ion-button expand="full" class="ion-no-margin" (click)="decreaseAmount()"
                                                [disabled]="amount <= lunchArticle.article.clickAmount">
                                        <ion-icon name="remove-outline"></ion-icon>
                                    </ion-button>
                                </ion-col>
                                <ion-col class="ion-text-center" size="4">
                                    <span>{{amount}} {{ lunchArticle.article?.sellUnit.label }}</span>
                                </ion-col>
                                <ion-col class="ion-text-center" size="4">
                                    <ion-button expand="full" class="ion-no-margin"
                                                [disabled]="amount >= lunchArticle.article.maxOrderAmount"
                                                (click)="increaseAmount()">
                                        <ion-icon name="add-circle-outline"></ion-icon>
                                    </ion-button>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
                                    <ion-button color="secondary" expand="full" class="ion-no-margin"
                                                (click)="addNoteToMenu()">
                                        <ion-icon name="create-outline"></ion-icon>
                                        Anmerkung
                                    </ion-button>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </mat-step>
                </mat-horizontal-stepper>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col size="6">
                    <ng-container *ngIf="menuConfigurationStepper.selectedIndex === 0">
                        <ion-button expand="full" (click)="modalCtrl.dismiss()">Abbrechen</ion-button>
                    </ng-container>
                    <ng-container *ngIf="menuConfigurationStepper.selectedIndex > 0">
                        <ion-button expand="full" (click)="menuConfigurationStepper.previous()">Zurück</ion-button>
                    </ng-container>
                </ion-col>
                <ion-col size="6">
                    <ng-container *ngIf="!isLastPage && lunchArticle.lunchCategories.length > 0">
                        <ion-button color="primary" expand="full"
                                    [disabled]="!lunchArticle.lunchCategories[menuConfigurationStepper.selectedIndex]?.checkedLunchArticles.length"
                                    (click)="menuConfigurationStepper.next()">Weiter
                        </ion-button>
                    </ng-container>
                    <ng-container *ngIf="isLastPage || lunchArticle.lunchCategories.length === 0">
                        <ion-button color="primary" expand="full" (click)="addLunchMenuToBasket()">In den Warenkorb
                        </ion-button>
                    </ng-container>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>

