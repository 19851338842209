import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ArticleService} from '../../services/article.service';
import {OrderResponse} from '../../models/order-response.model';
import {OrderService} from '../../services/order.service';
import {BasketService} from '../../services/basket.service';
import {OrderArticle} from '../../models/order-article.model';
import {Router} from '@angular/router';
import {Basket} from '../../models/basket.model';
import {CheckoutResponse} from '../../models/checkout-response.model';
import {BasketItem} from '../../models/basket-item.model';
import {OrderItemType} from 'src/app/enums/order-item-type.enum';
import {PaymentType} from 'src/app/enums/payment-type.enum';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.page.html',
    styleUrls: ['./order-detail.page.scss'],
})
export class OrderDetailPage implements OnInit {
    @Input() public order: OrderResponse;
    public checkoutResponse: CheckoutResponse = null;

    public isAddToBasketAvailable = false;
    public isInstantReBuyAvailable = false;
    private reBuyBasket: Basket = null;

    public PaymentType = PaymentType;

    constructor(
        private router: Router,
        private modalController: ModalController,
        public articleService: ArticleService,
        private orderService: OrderService,
        private basketService: BasketService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        if (!this.order) {
            return;
        }
        try {
            this.order = await this.orderService.getOrder(this.order.id);
            await this.checkIfOrderIsAvailable();
        } catch (e) {
            // TODO: handle error
        }
    }

    public async closeModal() {
        await this.modalController.dismiss();
    }

    public async addToBasket(orderArticles: OrderArticle[]) {
        for (const orderArticle of orderArticles) {
            if (orderArticle.additionalArticles && orderArticle.additionalArticles.length) {
                if (orderArticle.type === OrderItemType.DEFAULT) {
                    const article = await this.articleService.getArticle(orderArticle.baseArticle.id);

                    this.basketService.addMenuToBasket(
                        article.id,
                        article,
                        orderArticle.additionalArticles.map(a => a.serialize()),
                        orderArticle.count,
                        orderArticle.comment,
                        OrderItemType.MENU_ARTICLE
                    );
                }
            } else {
                const article = await this.articleService.getArticle(orderArticle.baseArticle.id);

                this.basketService.addArticleToBasket(
                    article,
                    orderArticle.count,
                    orderArticle.comment,
                    OrderItemType.DEFAULT,
                    orderArticle.articleAttributes
                );
            }
        }
        await this.closeModal();
    }

    public async checkIfOrderIsAvailable() {
        if (!this.order.articles.length) {
            return;
        }

        if (this.order.articles.filter(article => article.type === OrderItemType.LUNCH_ARTICLE).length) {
            return;
        }

        this.isAddToBasketAvailable = true;

        const basket = new Basket();
        for (const article of this.order.articles) {
            const articleToAdd = new BasketItem();
            articleToAdd.id = article.baseArticle.id;
            articleToAdd.amount = article.count;
            articleToAdd.comment = article.comment;
            articleToAdd.type = article.type;
            articleToAdd.additionalArticles = article.additionalArticles.map(additionalArticle => additionalArticle.serialize());
            articleToAdd.articleAttributes = article.articleAttributes.map(articleAttribute => articleAttribute.serialize());

            basket.items.push(articleToAdd);
        }

        if (basket.items.length) {
            try {
                this.checkoutResponse = await this.orderService.checkout(basket);
                this.reBuyBasket = basket;
                this.isInstantReBuyAvailable = true;
            } catch (e) {
                // TODO: handle error
            }
        }
    }

    async reBuyOrder() {
        this.basketService.reBuyBasket = this.reBuyBasket;
        await this.modalController.dismiss();
        await this.router.navigate(['/order']);
    }
}
