import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private loading: HTMLIonLoadingElement;

    constructor(private loadingController: LoadingController) {
    }

    public async createLoading() {
        this.loading = await this.loadingController.create({});
        await this.loading.present();
        return true;
    }

    public async dismissLoading() {
        if (typeof this.loading !== 'undefined') {
            await this.loading.dismiss();
        }
        return false;
    }
}
