import {Injectable} from '@angular/core';

export const UUID_STORAGE_KEY = 'device-uuid';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  public persist(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public retrieve(key: string): any {
    const item = localStorage.getItem(key);

    if (!item) {
      return null;
    }

    let ret;

    try {
      ret = JSON.parse(item);
    } catch (e) {
      ret = item;
    }

    return ret;
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }
}
