import {BaseArticle} from './base-article.model';
import {Serializable} from './serializable.abstract';
import {MenuCategory} from './menu-category.model';
import {MenuArticle} from 'src/app/models/menu-article.model';

export class ArticleDTO extends BaseArticle {
    menuCategories: MenuCategory[] = [];

    public deserialize(input: any): Serializable {
        super.deserialize(input);
        this.deserializeComplexArray('menuCategories', input, MenuCategory);
        return this;
    }
}

export class Article extends ArticleDTO {
    get additionalArticles(): Array<MenuArticle> {
        const ret = [];
        this.menuCategories.forEach(menuCategory => {
            menuCategory.checkedMenuArticles.forEach(menuArticle => {
                ret.push(menuArticle);
            });
        });
        return ret;
    }
}
