import {Component, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {Article} from '../../models/article.model';
import {ArticleService} from '../../services/article.service';
import {BasketService} from '../../services/basket.service';
import {DataService} from '../../services/data.service';

import {BaseArticle} from '../../models/base-article.model';
import {OverlayEventDetail} from '@ionic/core/components';
import {IonModal, ModalController} from "@ionic/angular";
import {ArticleAttribute} from "../../models/article-attribute.model";

import {ProductDetailPage} from "../../pages/products/product-detail/product-detail.page";

@Component({
    selector: 'product-extras-modal',
    templateUrl: './product-extras-modal.html',
    styleUrls: ['./product-extras-modal.scss'],
})
export class ProductExtrasModal implements OnInit {

    @ViewChild(IonModal) modal: IonModal;
    @ViewChild(ProductDetailPage) productDetailPage: ProductDetailPage;

    message = 'Add extras for the product';
    name: string;

    article: Article;
    selectedAttributes: ArticleAttribute[] = []

    constructor(
        public basketService: BasketService,
        public dataService: DataService,
        private modalCtrl: ModalController,
    ) {
    }

    async ngOnInit() {
        this.selectedAttributes.forEach(selectedAttribute => {
            this.article.articleAttributes.forEach(attribute => {
                if (selectedAttribute.id === attribute.id) {
                    attribute.checked = true;
                }
            });
        })
    }

    onCheckboxChange() {
        this.selectedAttributes = this.article.articleAttributes.filter(attribute => attribute.checked);
    }

    cancel() {
        return this.modalCtrl.dismiss(null, 'cancel');
    }

    addToBasketFromModal() {
        return this.modalCtrl.dismiss({attributes: this.selectedAttributes}, 'confirm');
    }

}
