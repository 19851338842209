export abstract class Serializable {
    id: number;

    public deserialize(input: any): Serializable {
        for (const field in input) {
            if (Object.prototype.hasOwnProperty.call(input, field)) {
                this[field] = input[field];
            }
        }

        return this;
    }

    public serialize(): any {
        return this;
    }

    protected deserializeDate(fieldName: string, input: any) {
        if (!input[fieldName]) {
            return;
        }

        this[fieldName] = new Date(input[fieldName]);
    }

    protected deserializeComplexArray<A extends Serializable>(fieldName: string, input: any, type: new() => A): void {
        if (!input[fieldName]) {
            return;
        }

        this[fieldName] = [];
        input[fieldName].forEach(item => {
            this[fieldName].push(new type().deserialize(item) as A);
        });
    }

    protected deserializeComplexType<A extends Serializable>(fieldName: string, input: any, type: new() => A): void {
        if (!input[fieldName]) {
            return;
        }

        this[fieldName] = new type().deserialize(input[fieldName]) as A;
    }

}
