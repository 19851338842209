<ng-container *ngIf="environment.desktop">
    <div class="footerDiv">
        <ion-grid class="footerGrid">
            <ion-row>
                <ion-col size="12" size-lg="8" offset-lg="2">
                    <ion-row>
                        <ion-col size="12" size-lg="2">
                            <div style="display: flex; align-items: center; height: 100%;">
                                <ng-container *ngIf="clientService.client?.banner && clientService.client?.logo">
                                    <img style="width: 200px"
                                         [src]="clientService.client?.logo.path"
                                         (load)="logoLoading = false"
                                         [hidden]="logoLoading">
                                </ng-container>
                            </div>
                        </ion-col>
                        <ion-col size="12" size-lg="4" class="contact-informations">
                            <div style="display: flex; justify-content: center; height: 100%; flex-direction: column">
                                <a href="http://www.google.at/maps/?q={{clientService.client?.street}},{{clientService.client?.zip}} {{clientService.client?.location}}"
                                   target="_blank" style="display: block; width: 100%">
                                    <ion-row style="width: 100%;">
                                        <ion-col size="1" style="display: flex; align-items: center">
                                            <ion-icon name="map-outline"></ion-icon>
                                        </ion-col>
                                        <ion-col size="11" style="display: flex; align-items: center">
                                            {{clientService.client?.street}}<br>
                                            {{clientService.client?.zip}} {{clientService.client?.location}}
                                        </ion-col>
                                    </ion-row>
                                </a>
                                <a href="tel:{{clientService.client?.phone}}" target="_blank"
                                   style="display: block; width: 100%">
                                    <ion-row style="width: 100%;">
                                        <ion-col size="1" style="display: flex; align-items: center">
                                            <ion-icon name="call-outline"></ion-icon>
                                        </ion-col>
                                        <ion-col size="11" style="display: flex; align-items: center">
                                            {{clientService.client?.phone}}
                                        </ion-col>
                                    </ion-row>
                                </a>
                                <a href="mailto:{{clientService.client?.email}}" target="_blank"
                                   style="display: block; width: 100%">
                                    <ion-row style="width: 100%;">
                                        <ion-col size="1" style="display: flex; align-items: center">
                                            <ion-icon name="mail-outline"></ion-icon>
                                        </ion-col>
                                        <ion-col size="11" style="display: flex; align-items: center">
                                            {{clientService.client?.email}}
                                        </ion-col>
                                    </ion-row>
                                </a>
                            </div>
                        </ion-col>
                        <ion-col size="12" size-lg="6">
                            <div [innerHTML]="clientService.client?.openingHours" class="openingHours"></div>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row class="footer">
                <ion-col size-lg="8" offset-lg="2" class="ion-no-padding">
                    <ion-row>
                        <ion-col><p>
                            &copy;&nbsp;{{clientService.client?.companyName}}&nbsp;|&nbsp;{{now | date: 'y'}}</p>
                        </ion-col>
                        <ion-col class="ion-text-right">
                            <ul class="footer-links">
                                <li>
                                    <a href="{{clientService.client?.conditionsUrl}}" target="_blank">AGB</a>
                                </li>
                                <li>
                                    <a href="{{clientService.client?.privacyUrl}}" target="_blank">Datenschutz</a>
                                </li>
                                <li>
                                    <a href="{{clientService.client?.imprintUrl}}" target="_blank">Impressum</a>
                                </li>
                            </ul>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ng-container>
